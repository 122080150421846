<template>
  <div class="cont">
    <div class="icon icon-loading"></div>
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>
<style scoped lang="scss">
.cont
{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top:0;
  left:0;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color:#f4f3f2;
  // z-index:1000;

  .icon
  {
    width:200px;
    height:200px;
  }
}
</style>
