<template>
  <div id="app" v-bind:class="[isIos() ? 'ios' : '']">
    <Menu class="header" v-bind:selected="_router.currentRoute.name" v-bind:selectedLanguage="_language.language" v-bind:menu="_data.menu" v-on:action="onAction" />
    <transition name="fade" mode="out-in">
      <router-view v-bind:key="_data.pageKey" v-on:action="onAction"></router-view>
    </transition>
    <transition name="fade" mode="out-in">
      <Message v-if="_data.message.opened" v-bind:data="_data.message" v-bind:lang="_language.language" v-on:action="onAction" />
    </transition>
    <Footer class="footer" v-bind:data="_data.footer"/>
    <transition name="fade" mode="out-in">
      <Loading v-if="_data.loading"></Loading>
    </transition>
  </div>
</template>
<script>
import Actions from '@/services/Actions.js'
import Loading from '@/components/elements/Loading.vue'
import Menu    from '@/components/Menu.vue'
import Message from '@/components/Message.vue'
import Footer  from '@/components/Footer.vue';

export default {
  components: 
  {
    Menu,
    Footer,
    Loading,
    Message
  },
  created:function()
  {
    this._data     = this.$root.appData;
    this._router   = this.$root.$router;
    this._language = this.$root.languages;
    this._actions  = this.$root.actions;
  },
  methods:
  {
    onAction:function(type,data)
    {
      this._actions.onAction(type,data);
    },
    isIos:function()
    {
      return ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
    }
  }
} 
</script>
<style lang="scss">

@import './Global';

#app
{
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header
  {
    position: absolute;
    z-index: 100;
    top:0;
    flex-shrink: 0;
  }
}

.fade-enter-active, .fade-leave-active 
{
  transition: opacity .2s ease;
}
.fade-enter, .fade-leave-active 
{
  opacity: 0;
}
</style>
