const AppData =
{
    languages:null,
    general:
    {
        service:'https://api.chinese.ge/Public/',
        // service:'http://116.203.24.175:5050/Public/',
        url    :'http://ec2-18-188-251-15.us-east-2.compute.amazonaws.com:8080/',
        //email  :'info@chinese.ge',
        email  :'DictionaryFeedback@gmail.com',
    },
    menu:
    {
        languages:
        [
            {title:'ქართ.',key:'ge'},
            {title:'中文'  ,key:'ch'}
        ],
        buttons:
        [
            {
                title :'aboutDictionary',
                opened:false,
                button:true,
                sub   :
                [
                    {title:'dedication'     ,path:'/dedication'},
                    {title:'dictionary'     ,path:'/dictionary'},
                    {title:'ourTeam'        ,path:'/ourTeams'  },
                    {title:'teamValues'     ,path:'/teamValues'},
                    {title:'aboutDictionary',path:'/about'},
                ]
            },
            {
                title :'supportMaterial',
                opened:false,
                button:true,
                sub   :
                [
                    {title:'transcriptions_table',path:'/transcriptions'},
                    {title:'abbreviations_table' ,path:'/abbreviations' },
                    {title:'measures_table'      ,path:'/measures'      },
                    {title:'seasons'             ,path:'/seasons'       },
                    {title:'ethnical_groups'     ,path:'/ethnic'        },
                    {title:'historical_periods'  ,path:'/chronology'    },
                    {title:'holidays'            ,path:'/holidays'      },
                    {title:'chemical_elements'   ,path:'/chemical'      },
                    {title:'library'             ,path:'/library'       }
                ]
            },
            {
                title :'contact',
                path  :'/contact',
                button:true
            }
        ]
    },
    footer:
    {
        notice:'noticeText',
        date:'© 2013-'
    },
    message:
    {
        title :{ge:'',ch:''},
        text  :{ge:'',ch:''},
        opened:false,
        date  :'',
        token :''
    },
    views:
    {
        main:null,
        abbreviations:null,
        chemicalElements:null,
        chronology:null,
        ethnic:null,
        measures:null,
        transcriptions:null,
        holidays:null,
        translations:null
    },
    searchResults  :[],
    selectedArticle:null,
    loading:false,
    pageKey:''
};

module.exports = AppData;