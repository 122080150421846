<template>
  <div class="viewCont">
    <ArticleCont class="cont" v-bind:strokePadding="0" v-bind:strokeSize="64" v-bind:title="data.title.ge" v-bind:title2="data.title.ch">
      <div class="content">
        <div v-bind:class="['block',block.class]" v-for="(block,blockIndex) in data.blocks" v-bind:key="'block_'+blockIndex">
          <div class="item" v-for="(item,itemIndex) in block.list" v-bind:key="'item'+blockIndex+'-'+itemIndex">
            <div class="num">{{item.num}}</div>
            <div class="val1">{{item.val1}}</div>
            <div class="val2" v-if="item.val2">{{item.val2}}</div>
            <div class="meaning">{{item.meaning}}</div>
          </div>
        </div>
      </div>
    </ArticleCont>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'Abbreviations',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  mounted:function()
  {
     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 100%;
  height: auto;

  .cont
  {
    position: relative;
    margin-top: 80px;

    .content
    {
      width: 80%;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      font-feature-settings: initial;

      .block
      {
        font-size: 14px;

        .item
        {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          border-bottom: solid 1px #dedede;

          .num
          {
            width: 10%;
          }

          .val1
          {
            width: 20%;
            font-style: italic;
          }

          .val2
          {
            width: 20%;
          }

          .meaning
          {
            text-align: right;
            width: 70%;
          }

        }
        margin-bottom: 50px;
      }

      .firstBlock
      {
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        justify-content: space-between;

        .item
        {
          width: 45%;

          .num
          {
            font-style: italic;
          }
        }
      }

      .secondBlock
      {
        .item
        {
          .val1
          {
            color: #588e67;
            font-style: normal !important;
          }
        }
      }

      .thirdBlock
      {
        .item
        {
          .val1
          {
            color: #fe9a53;
            font-style: normal !important;
          }
        }
      }

      .fourthBlock
      {
        .item
        {
          .val1
          {
            color: #9e363a;
            font-style: normal !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .articleCont
  {
    overflow: hidden;
    margin-bottom: 10px !important;
    
    /deep/ .titleCont
    {
        overflow: hidden;
        font-size: 16px !important;
        width: 90% !important;
        margin-top: 0px !important;
        margin-right: 0% !important;
        margin-left: 0% !important;
        padding-left: 5% !important;
        padding-top: 0% !important;
    }

    .container
    {
      .content
      {
        margin-top: 10px !important;
        margin-left: 5% !important;
        width: 90% !important;

        .block 
        {
          width: 100% !important;
          display: block !important;
          font-size:12px !important;

          .item
          {
            width: 100% !important;

            div
            {
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
</style>
