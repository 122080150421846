<template>
  <div class="dictionaryViewCont">
    <ArticleCont class="articleCont" title="dictionary" v-bind:bold="true" v-bind:strokePadding="55" v-bind:strokeSize="120">
      <div class="centerCont">
        <div class="content" v-html="getWord('texts.dictionary.content')"></div>
      </div>
    </ArticleCont>
    <Background class="background" color="fcd73d" v-bind:index="bgIndex" v-bind:backgrounds="backgrounds" >
      <div class="counterCont">
        <div class="title">{{getWord('texts.dictionary.counterLabel')}}</div>
        <div class="counter">{{numberFormat(wordNumber)}}</div>
      </div>
    </Background>
  </div>
</template>

<script>
import Background    from './../elements/Background.vue';
import ArticleCont   from './../elements/ArticleCont.vue';
import BaseComponent from './../base/BaseComponent.vue';

export default {
  name: 'DictionaryView',
  extends:BaseComponent,
  components: 
  {
    Background,
    ArticleCont
  },
  data:function()
  {
    return {
      wordNumber: 0,
    }
  },
  props:
  {
    backgrounds:Array,
    wordCount:Number,
    bgIndex:Number
  },
  watch:
  {
    wordCount:function(newVal,oldVal)
    {
      this.wordNumber = this.wordCount - 200;
      this.incrementNumber();
    }
  },
  methods:
  {
    numberFormat:function(x)
    {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    incrementNumber:function()
    {
      if(this.wordNumber<this.wordCount)
      {
        let max       = 100;
        let baseTime  = 30;
        let multiTime = 4;
        let time      = this.wordCount - this.wordNumber < max ? baseTime + (multiTime * (max - (this.wordCount - this.wordNumber))) : baseTime;
        this.wordNumber++;
        setTimeout(()=>
        {
          this.incrementNumber();
        },time);
      }
    }
  },
  mounted:function()
  {
    if(this.wordCount>0)
    {
      this.wordNumber = this.wordCount - 200;
      this.incrementNumber();
    }
  }
}
</script>
<style scoped lang="scss">
.dictionaryViewCont
{
  display: block;
  position: relative;
  width: 100%;
  height: 440px; 
  background-color: #f4f3f2;
  
  .articleCont
  {
      position: relative;
      height: calc(100% - 100px);
      .centerCont
      {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        font-style: italic;
        font-feature-settings:normal;

        .content
        {
          padding: 10px;
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          width: 70%;
          min-height: 100px;
          border-radius: 10px;
          text-align: center;
          background-color: #f0f0f0;
          font-size: 0.82rem;
          box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
        }
      }
  }

  .background
  {
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    .counterCont
    {
      font-style: italic;
      font-feature-settings:normal;
      // border-left : solid 2px rgba($color: #ffffff, $alpha: 0.6);
      // border-right: solid 2px rgba($color: #ffffff, $alpha: 0.6);
      width: 380px;
      height: 72px;
      overflow: hidden;
      color: #ffffff;
      text-align: center;

      .title
      {
        font-size: 11px;
      }

      .counter
      {
        font-style:normal;
        font-weight: bold;
        margin-top: -12px;
        font-size: 70px;
        font-variant-numeric: tabular-nums;
      }
    }
  }
}
</style>
