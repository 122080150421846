<template>
  <div class="viewCont">
    <div class="viewCont-Content">
      <div class="item" v-for="(item, index) in data.items" v-bind:key="'item_'+item.date.text">
        <div class="left">
          <div class="text"       >{{item.texts.ch}}</div>
          <div class="text"       >{{item.texts.ge}}</div>
          <div class="annotation" v-if="item.texts.annotation">{{item.texts.annotation}}</div>
        </div>
        <div class="mid">
          <div v-bind:class="['border',(index == 0 ? 'start' : (index == data.items.length-1 ? 'end' : ''))]"></div>
          <div class="line"></div>
          <div class="ball"></div>
          <div class="ball-small"></div>
        </div>
        <div class="right">
          <div class="text"       >{{item.date.text}}</div>
          <div class="annotation" v-if="item.date.annotation">{{item.date.annotation}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'Holidays',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  methods:
  {
    scroll:function()
    {
      let scroll = this.$route.params.id ? this.data[this.$route.params.id].pos : 0;
      
      window.scrollTo({
        top: scroll,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  watch:
  {
    '$route'()
    {
      this.scroll();
    }
  },
  mounted:function()
  {
    this.scroll();
  }
}
</script>
<style scoped lang="scss">

$marginTop:20px;
$marginSides:10px;

.viewCont
{
  display: block;
  width: 96%;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  .viewCont-Content
  {
    margin-top: 100px;
    margin-bottom: 50px;

    .item
    {
      display: flex;
      min-width:370px;
      font-size:12px;
      font-feature-settings:initial;
      margin-bottom:$marginTop;

      .left
      {
        display:block;
        text-align:right;
        width:calc(50% - 40px);

        .text
        {
          font-weight:bold;
        }

        .annotation
        {
          font-size:10px;
          font-style:italic;
        }
      }

      .mid
      {
        width:60px;
        position:relative;
        margin-left:$marginSides;
        margin-right:$marginSides;

        .border
        {
          position:absolute;
          width:1px;
          height:calc(100% + #{$marginTop});
          left:calc(50% - 0.5px);
          background-color:#000000;
        }

        .start
        {
          top:50%;
          height:calc(50% + #{$marginTop});
        }

        .end
        {
          height:50%;
        }

        .ball
        {
          position:absolute;
          width:12px;
          height:12px;
          border-radius:50%;
          top:calc(50% - 6px);
          left:calc(50% - 6px);
          background-color:#ffcc00;
        }

        .line
        {
          position:absolute;
          height:2px;
          width:40%;
          top:calc(50% - 2px);
          left:50%;
          background-color:#ffcc00;
        }

        .ball-small
        {
          position:absolute;
          width:6px;
          height:6px;
          border-radius:50%;
          top:calc(50% - 3px);
          left:calc(90% - 3px);
          background-color:#ffcc00;
        }
      }

      .right
      {
        display:flex;
        text-align:left;
        justify-content: center;
        flex-direction: column;
        width:calc(50% - 40px);

        .text
        {
        }

        .annotation
        {
          font-size:10px;
          font-style:italic;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .item
  {
    min-width: unset !important;
  }
}
</style>
