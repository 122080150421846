<template>
  <div v-bind:class="['searchCont',loading ? 'disabledInteraction' : '']">
    <div v-bind:class="['blend',(items && wordItems.length>0 && !isWord() ? 'showBlend' : '')]"></div>
    <div class="inputCont" v-bind:style="{marginTop:(items && wordItems.length>0 && !isWord() ? offsetWhenItemsVisible+'px' : 0)}">
      <input class='field' type="text" v-model="word" v-bind:placeholder = "searchType == 'text' ? getWord('searchHolder') : searchType == 'doc' ? getWord('decomposeText') : getWord('searchInText')" v-on:input="onInput" />    
      <div v-bind:class="['loading','icon-loading',loading == true ? 'enabled' : '']"></div>
      <div class="iconCont">
        <div v-bind:class="['button',(searchType == 'text'  ? 'selected' : '')]" v-on:click="onToggle('text' )"><div class="icon icon-magnifier-mask"></div></div>
        <div v-bind:class="['button',(searchType == 'thing' ? 'selected' : '')]" v-on:click="onToggle('thing')"><div class="icon icon-document-mask"></div></div>
        <div v-bind:class="['button',(searchType == 'doc'   ? 'selected' : '')]" v-on:click="onToggle('doc' )"><div class="icon icon-thing-mask"></div></div>
      </div>
    </div>
    <div v-if="((!isWord() && (wordItems.length == 0 || wordItems[0]==null)) || (isWord() && (textItems.length == 0 || textItems[0]==null))) && word !='' && !loading" class="noResult">
      {{!isWord() ? getWord('noItemsSearch2') : getWord('noItemsSearch1')}}
    </div>
    <ScrollableContainer class="itemsCont" v-if="textItems && textItems.length>0 && isWord()">
      <div class="items">
        <div class="item" v-for="(item,index) in textItems" v-bind:key="'searchItem_'+index" v-on:click="onItem(item)">{{item.title}}</div>
      </div>
    </ScrollableContainer>
    <div v-bind:class="['itemsContHolder',(wordItems && wordItems.length>0 && !isWord() ? 'enabled' : '')]">
      <ScrollableContainer class="itemsCont">
        <div class="items">
          <div class="item" v-for="(item,index) in wordItems" v-bind:key="'searchItem_'+index" v-on:click="onItem(item)">
            <div class="word">{{item.key}}</div>
            <div class="meanings">
              <div v-bind:class="['meaning',(item.type == 'search' ? 'searchMeaning' : '')]"  v-for="(meaning,index2) in item.meanings" v-bind:key="'meaning_'+index2" v-html="meaning"></div>
            </div>
          </div>
        </div>
      </ScrollableContainer>
    </div>
  </div>
</template>
<script>
import ScrollableContainer from './ScrollableContainer.vue';
import BaseComponent       from '../base/BaseComponent.vue';

export default {
  name: 'InputDropdown',
  extends:BaseComponent,
  components:
  {
    ScrollableContainer
  },
  data:function()
  {
    return {
      word:'',
      loading:false,
      searchType:'text',
      textItems:[],
      wordItems:[]
    }
  },
  props:
  {
    items                 :Array,
    offsetWhenItemsVisible:Number
  },
  watch:
  {
    items:function(newVal,oldVal)
    {
      this.textItems = [];
      this.wordItems = [];
      this.loading   = false;
      
      if(newVal.length == 1 && newVal[0] == null)
      {
        return;
      }
      if(this.searchType != 'text')
      {
        this.wordItems = newVal;
      }
      else
      {
        this.textItems = newVal;
      }
    }
  },
  methods:
  {
    isWord:function()
    {
      return this.searchType == 'text'
    },
    onToggle:function(searchType)
    {
      this.textItems  = [];
      this.wordItems  = [];
      this.searchType = searchType;
      this.onInput();
    },
    onInput:function(e)
    {
      this.loading = this.word.length>0;
      this.onAction(this.searchType == 'text' ? 'onInput' : this.searchType == 'doc' ? 'onWordInput' : 'onArticleInput',this.word);
    },
    onItem:function(e)
    {
      this.onAction('onItem',{data:e,key:(this.searchType == 'thing' ? this.word : '')});
    },
    onKeyPress:function(e)
    {
      if(e.keyCode == 13 && this.items && this.items.length>0)
      {
        if(this.word == this.items[0].title)
        {
          this.onAction('onItem',{data:this.items[0],key:(this.searchType == 'thing' ? this.word : '')});
        } 
      }
    }
  },
  created:function()
  {
    document.addEventListener('keyup', this.onKeyPress, false);
  },
  destroyed:function()
  {
    document.removeEventListener('keyup', this.onKeyPress, false);
  }
}
</script>
<style scoped lang="scss">
.searchCont
{
  width: 100%;
  position: relative;

  .blend
  {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #f2f0ed;
    background: linear-gradient(180deg, rgba(242,240,237,1) 0%, rgba(242,240,237,0) 100%);
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    transition: all 1s;

    &.showBlend
    {
      opacity: 1;
      pointer-events: all;
    }
  }
  
  .inputCont
  {
    z-index: 10;
    width: 100%;
    height: 36px;
    border-radius: 25px;
    background-color: #fafafa;
    display: flex;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.5);
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    transition: all .5s;

    .field
    {
      background-color: transparent;
      width: calc(100% - 130px);
      padding-left: 20px;
      font-size: 16px;
      color: #333333;
      outline: none;
      border:none;
      font-family: 'FiraGO', Helvetica, Arial, sans-serif;
      font-feature-settings: initial;
          
      &:focus
      {
        outline: none;
      }

      &::placeholder
      {
        color: #cccccc;
        font-style: italic;
      }
    }

    .loading
    {
      width: 30px;
      height: 35px;
      margin-right: 10px;
      position: relative;
      opacity: 0;
      transition: all .5s;

      &.enabled
      {
        opacity: 1;
      }
    }

    .iconCont
    {
      width:120px;
      height: 100%;
      background-color: #ffce00;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      position: absolute;
      right: 0px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      transition: all .5s;

      .button
      {
        width: 30px;
        height: 30px;
        cursor: pointer;

        .icon
        {
          width: 100%;
          height: 100%;
          background-color: #fff;
          transition: all .5s;
          flex-shrink: 0;
          mask-size: 70% 70%;
        }
      }

      .selected
      {
        border-radius: 50%;
        background-color: #fff;

        .icon
        {
          width: 100%;
          height: 100%;
          background-color: #ffce00;
        }
      }
    }
  }

  .itemsCont
  {
    position: absolute;
    width: calc(100% - 42px);
    height: 134px;
    margin-top: 5px;
    background-color:rgba($color: #ffffff, $alpha: 0.8);
    border-radius: 10px;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.5);
    transition: all .5s;
    z-index: 100;

    .items
    {
      height: auto;
      width:100%;

      .item
      {
        color: #474e50;
        height: 22px;
        transition: all .5s;
        padding-left: 20px;
        width: calc(100%- 20px);
        cursor: pointer;

        &:hover
        {
          background-color: #f2efed;
        }
      }
    }
  }

  .itemsContHolder
  {
    margin-top: 50px;
    transition: all 1s;
    opacity: 0;
    pointer-events: none;
    position: relative;

    &.enabled
    {
      pointer-events: all;
      opacity: 1;
      z-index: 10;
    }
    
    .itemsCont
    {
      margin-left: -25%;
      width: 150%;
      max-height: 480px;
      height: unset;
      background-color: #f9f7f4;
      border-radius: 2px;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
      
      .item
      {
        width: 100%;
        height: auto;
        display: flex;
        border-bottom: solid rgba($color: #000000, $alpha: 0.1) 1px;
        padding: 0;
        padding-top: 2px;
        padding-bottom: 2px;
        position: relative;
        overflow: hidden;
        
        .word
        {
          width: 10%;
          height: calc(100% - 2px);
          background-color: #fff;
          position: absolute;
          display: flex;
          justify-content: center;
          padding-top: 2px;
          text-decoration: underline;
        }

        .meanings
        {
          font-size: 14px;
          width: 88%;
          margin-left: 11%;
          padding-top: 2px;
          font-feature-settings:initial;

          .meaning
          {
            padding-top: 3px;
            padding-bottom: 3px;
            display: flex;

            /deep/
            .left
            {
              width: 15% !important;
              display: flex;
              justify-content: flex-end;

              &.count_0
              {
                width: 1% !important;
              }

              &.count_1
              {
                width: 2% !important;
              }

              &.count_2
              {
                width: 5% !important;
              }

              &.count_3
              {
                width: 8% !important;
              }

              &.count_4
              {
                width: 16% !important;
              }

              span
              {
                margin-right: 5px;
              }
            }

            /deep/
            .right
            {
              margin-left: 1%;
              width: 85% !important;
            }

            /deep/
            .dict-curly-brackets
            {
              color:#39a0ca;
              font-weight: bold;
            }

            /deep/
            .spacing
            {
              padding-left: 5px;
              padding-right: 5px;
            }

            /deep/
            .link_pic
            {
              cursor: pointer;
            }

            /deep/
            .link_pic_icon
            {
              background-image: url(./../../assets/img/icons/picture.png);
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              width: 15px;
              height: 13px;
              display: inline-block;
              margin-left: 5px;
              margin-right: 5px;
              pointer-events: none;
            }

            /deep/
            .link_pic_text
            {
              pointer-events: none;
            }

            /deep/
            .dict-roman-numbers
            {
              font-weight: bold;
              color: #2c7873;
            }
            
            /deep/
            .dict-numbers
            {
              // font-weight: bold;
              color: #000000;
            }
            
            /deep/
            .dict-chinese-grammar
            {
              color:#478559;
            }

            /deep/
            .dict-angle-brackets
            {
              color:#7f00ff;
            }

            /deep/
            .dict-chinese-brackets
            {
              color:#3088ea;
            }

            
            /deep/
            .dict-example
            {
              font-weight: bold;
              color: #000000;
            }

            /deep/
            .bullet
            {
              margin-left: 30px;
            }

            /deep/
            .dict-see-also
            {
              color: #000000;
              text-decoration: underline;
            }
          }

          .searchMeaning
          {
            display: block;

            /deep/
            .currentSearchedWord
            { 
              color: #3088ea;          
              // background-color: #fcd73d;
              display: inline;
            }
          }
        }
      }
    }
  }

  .noResult
  {
    font-feature-settings:initial;
    background-color:rgba($color: #fbf9f7, $alpha: 0.95);
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
    width: 90%;
    margin-top: 20px;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
  }

  &.disabledInteraction
  {
    pointer-events: none !important;
  }
}

@media only screen and (max-width: 1000px)
{
  /deep/ .itemsContHolder
  {
    margin-top: 10px !important;

    .itemsCont
    {
      width: 100% !important;
      margin-left: 0% !important;
    }
  }
}
</style>
