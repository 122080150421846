<template>
  <div class="message">
    <div class="close" v-on:click="onClose()">X</div>
    <div class="message-container">
      <div class="message-content">
        <div class="row">
          <div class="title">
            {{data.title[lang]}}
          </div>
          <!-- <div class="date">
            {{data.date}}
          </div> -->
        </div>
        <div class="text">
          {{data.text[lang]}}
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </div>
</template>

<script>
import BaseComponent from './base/BaseComponent.vue';

export default {
  name: 'Message',
  extends:BaseComponent,
  props:
  {
    data:Object,
    lang:String
  },
  data:function()
  {
    return {
    }
  },
  methods:
  {
    onClose:function()
    {
      this.onAction('closeMessage');
    }
  }
}
</script>
<style scoped lang="scss">
.message
{
  width: 80%;
  max-width: 1000px;
  min-height: 200px;
  max-height: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:rgba($color: #fbf9f7, $alpha: 0.95);
  position: fixed;
  z-index: 50;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);

  .message-container
  {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;

    .message-content
    {
      padding: 5%;
      color: #434a4c;
      line-break: anywhere;

      .row
      {
        width: 100%;
        height: 30px;
        margin-bottom: 5%;
        display: flex;
        justify-content: space-between;

        .title
        {
          color: #ffce00;
        }

        .date
        {
          color: #ffce00;
          font-feature-settings:initial;
        }
      }

      .text
      {
        font-feature-settings:initial;
      }
    }

    .divider
    {
      width: 50%;
      height: 2px;
      max-width: 200px;
      background-color: #fed62e;
      margin-bottom: 2%;
    }
  }
  

  .close
  {
    font-size: 30px;
    font-family: cursive;
    color: #fed62e;
    cursor: pointer;
    transition: all .5s;
    position: absolute;
    right: 0;
    top: -40px;

    &:hover
    {
      opacity: 0.5;
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .message 
  {
    font-size: 12px;
    .message-content
    {
      padding: 3% !important;
    }

    .divider
    {
      bottom: 3%;
      position: absolute;
    }
  }
}
</style>
