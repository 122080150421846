try 
{
    // const jsdom = require("jsdom");

    // if(jsdom)
    // {
    //     const { JSDOM } = jsdom;
    
    //     const dom = new JSDOM(``);
    
    //     document = dom.window.document;
    // }
}
catch (e){}

let DomParser = 
{
    _getElementContent(el,excludeClasses)
    {
        excludeClasses = excludeClasses ? excludeClasses : [];
        let arr = [];

        if(el.childNodes.length>0)
        {
            for(let num = 0; num < el.childNodes.length; num++)
            {
                if(el.childNodes[num].tagName)
                {
                    let str = "";
                    if(excludeClasses.indexOf(el.childNodes[num].getAttribute('class'))==-1)
                    {
                        if(el.childNodes[num].childNodes.length>0)
                        {
                            str += this._getElementContent(el.childNodes[num],excludeClasses);
                        }
                    }
                    arr.push(str);
                }
                else
                {
                    arr.push(el.childNodes[num].nodeValue);
                }
            }
        }
        else
        {
            arr.push(el.nodeValue);
        }
        return arr.join("");
    },

    _containsElement(el,className,arr)
    {
        arr = !arr ? [] : arr;

        if(el.tagName)
        {
            if(el.getAttribute('class') == className)
            {
                arr.push(el);
            }
            if(el.childNodes.length>0)
            {
                for(let num = 0; num < el.childNodes.length; num++)
                {
                    this._containsElement(el.childNodes[num],className,arr);
                }
            }
        }
        return arr;
    },

    _containsElementByTag(el,tag,arr)
    {
        arr = !arr ? [] : arr;

        if(el.tagName)
        {
            if(el.tagName == tag)
            {
                arr.push(el);
            }
            if(el.childNodes.length>0)
            {
                for(let num = 0; num < el.childNodes.length; num++)
                {
                    this._containsElementByTag(el.childNodes[num],tag,arr);
                }
            }
        }
        return arr;
    },

    _hasNewLine(el)
    {
        if(el.tagName)
        {
            if(el.tagName.toLocaleLowerCase() == 'br')
            {
                return true;
            }
            if(el.childNodes.length>0)
            {
                return this._hasNewLine(el.childNodes[el.childNodes.length-1]);
            }
        }
        return false;
    },

    _getCharIndexes(row,char)
    {
        let indices = [];
        for(let num = 0; num < row.length; num++)
        {
            if(row[num] == char)
            {
                indices.push(num);
            }
        }
        return indices;
    },

    _parseText(text,startChar,endChar)
    {
        let start     = this._getCharIndexes(text,startChar);
        let end       = this._getCharIndexes(text,endChar);
        let extracted = [];

        if(start.length == end.length)
        {
            for(let num = 0; num < start.length; num++)
            {
                extracted.push(text.substring(start[num],end[num]+1));
            }
            for(let num = 0; num < extracted.length; num++)
            {
                text = text.replace(extracted[num],'');
            }
        }
        return {extracted:extracted,text:text};
    },

    _polishArray(arr)
    {
        for(let num = arr.length-1; num >=0 ; num--)
        {
            arr[num].content = arr[num].content.replace('///','');


            if(num+1<arr.length && arr[num+1].class != 'dict-see-also')
            {
                arr[num].class = '';
            }
            
            if(arr[num].class == ''            ||
               arr[num].class == 'bullet'      ||
               arr[num].class == 'dict-example')
            {
                let className = arr[num].class;
                let obj       = this._parseText(arr[num].content,'【','】');

                if(obj.extracted.length>0)
                {
                    arr[num].content = obj.text.trim();

                    for(let num2 = 0; num2 < obj.extracted.length; num2++)
                    {
                        arr.splice(num+1,0,{class:(className == '' || className == 'bullet' ? 'dict-list-brackets' : 'dict-chinese-brackets'),content:obj.extracted[num2]});
                    }
                }
            }

            if(num+2<arr.length && arr[num+1].class == 'dict-italic')
            {
                let obj = {class:'bullet-see',content:''};
                let see = arr.splice(num,4);

                arr.splice(num,0,obj);

                for(let num2 = 0; num2 < see.length; num2++)
                {
                    obj.content+=see[num2].content;
                }
            }

            arr[num].content = arr[num].content.trim();
        }
        return arr;
    },
    
    _divideArray(arr)
    {
        let list = [];
        let temp = null;
        for(let num = 0; num < arr.length; num++)
        {
            if(arr[num].class == 'start'             ||
               arr[num].class == 'dict-numbers'      ||
               arr[num].class == 'dict-roman-numbers')
            {
                if(temp)
                {
                    list.push(temp);
                    temp = null;
                }
                temp = [];
                temp.push(arr[num]);
            }
            else
            {
                if(temp)
                {
                    temp.push(arr[num]);
                }
            }
        }
        if(temp && temp.length>0)
        {
            list.push(temp);
        }
        return list;
    },

    _divideToCategories(list)
    {
        let arr = [];
        for(let num1 = 0; num1 < list.length; num1++)
        {
            let obj = {titles:null,bullets:null};
            for(let num2 = 0; num2 < list[num1].length; num2++)
            {
                let listObj = list[num1][num2];

                if(listObj.class == 'bullet' || listObj.class == 'bullet-see')
                {
                    obj.bullets = list[num1].splice(num2);
                    obj.titles  = list[num1];
                    break;
                }
            }
            if(!obj.titles)
            {
                obj.titles = list[num1];
            }
            arr.push(obj);
        }
        return arr;
    },

    _generateTitle(obj,titles)
    {
        obj.title = '';
        for(let num = 0; num < titles.length; num++)
        {
            let listObj = titles[num];

            if(listObj.class == 'dict-numbers')
            {
                obj.number = listObj.content.replace(')','');
            }
            if(listObj.class == 'dict-roman-numbers')
            {
                obj.roman  = listObj.content.replace(')','');
            }
            if(listObj.class == 'dict-chinese-grammar')
            {
                obj.grammar = obj.grammar ? obj.grammar : [];
                obj.grammar.push(listObj.content);  
            }
            if(listObj.class == 'dict-chinese-brackets' ||
               listObj.class == 'dict-list-brackets')
            {
                obj.same = obj.same ? obj.same : [];
                obj.same.push(listObj.content.replace('【','').replace('】',''));  
            }
            if(listObj.class == 'dict-angle-brackets')
            {
                obj.chars = obj.chars ? obj.chars : [];
                obj.chars.push(listObj.content.replace('〈','').replace('〉',''));  
            }
            if(listObj.class == 'dict-see-also')
            {
                obj.see = obj.see ? obj.see : [];
                obj.see.push(listObj.content);   
            }
            if(listObj.class == 'start' || listObj.class == '' || listObj.class == 'dict-example')
            {
                obj.title += ' '+listObj.content;
            }
        }

        obj.title = obj.title.replace(' (  ანტ. )','');
        // obj.title = obj.title.replace('[','');
        // obj.title = obj.title.replace(']','');
        obj.title = obj.title.trim();

        if(obj.title[obj.title.length-1] == ':')
        {
            obj.title = obj.title.substring(obj.title.length-1,1);
        }
    },

    _generateBullets(obj,texts)
    {
        obj.texts = [];
        let temp  = null;
        for(let num = 0; num < texts.length; num++)
        {
            let listObj = texts[num];

            if(listObj.class == 'bullet'    )
            {
                temp = {};
                obj.texts.push(temp);
                temp.text = listObj.content;
            }
            if(listObj.class == 'bullet-see')
            {
                temp = {};
                obj.texts.push(temp);
                temp.see = temp.see ? temp.see : [];
                temp.see.push(listObj.content);
            }
            if(listObj.class == '' || listObj.class == 'dict-example')
            {
                temp.text = temp.text ? temp.text : '';
                temp.text += ' '+listObj.content;
            }
            if(listObj.class == 'dict-chinese-grammar')
            {
                temp.grammar = temp.grammar ? temp.grammar : [];
                temp.grammar.push(listObj.content);  
            }
            if(listObj.class == 'dict-chinese-brackets' ||
               listObj.class == 'dict-list-brackets')
            {
                temp.same = temp.same ? temp.same : [];
                temp.same.push(listObj.content.replace('【','').replace('】',''));  
            }
            if(listObj.class == 'dict-angle-brackets')
            {
                temp.chars = temp.chars ? temp.chars : [];
                temp.chars.push(listObj.content.replace('〈','').replace('〉',''));  
            }
            if(listObj.class == 'dict-see-also')
            {
                temp.see = temp.see ? temp.see : [];
                temp.see.push(listObj.content);   
            }
        }

        obj.title = obj.title.replace(' (  ანტ. )','');
        // obj.title = obj.title.replace('[','');
        // obj.title = obj.title.replace(']','');
        obj.title = obj.title.trim();

        if(obj.title[obj.title.length-1] == ':')
        {
            obj.title = obj.title.substring(obj.title.length-1,1);
        }
    },

    _generateObject2(list)
    {
        list = this._divideArray(list);
        list = this._divideToCategories(list);

        let arr = [];


        for(let num = 0; num < list.length; num++)
        {
            let obj = {};

            this._generateTitle(obj,list[num].titles);
            if(list[num].bullets)
            {
                this._generateBullets(obj,list[num].bullets);
            }
        }
    },

    _getAllTextNodes(el,arr)
    {
        arr = arr ? arr : [];
        if(el)
        {
            for(let num = 0; num < el.childNodes.length; num++)
            {
                if(!el.childNodes[num].getAttribute)
                {
                    arr.push(el.childNodes[num]);
                }
                else
                {
                    if(el.childNodes[num].childNodes && el.childNodes[num].childNodes.length>0)
                    {
                        this._getAllTextNodes(el.childNodes[num],arr);
                    }
                }
            }
        }
        return arr;
    },

    _sanitizeDom(el)
    {
        this._flattenText(el);
        // this._sanitizeLinks(el);
        this._sanitizeTexts(el);

        // document.body.appendChild(el);
        return this._refineObject(this._generateObject(el));
    },

    _refineObject(arr)
    {
        // console.log("REFINING START",JSON.parse(JSON.stringify(arr)));

        for(let num1 = arr.length - 1; num1 >=0 ; num1-- )
        {
            if(Object.keys(arr[num1]).length == 0)
            {
                arr.splice(num1,1);
            }
            else
            {
                if(arr[num1].texts)
                {
                    let obj = arr[num1];
                    for(let num2 = obj.texts.length -1; num2 >= 0; num2-- )
                    {
                        if(obj.texts[num2].example)
                        {
                            let copy  = Object.assign({},obj.texts[num2]);
                            let copy2 = {text:obj.texts[num2].text};
                            delete copy.text;

                            obj.texts[num2] = copy2;

                            // console.log("HAS",obj,copy);
                            
                            arr.splice(num1+1,0,copy);
                        }
                    }
                }
            }
        }

        // console.log("REFINING END",JSON.parse(JSON.stringify(arr)));
        return arr;
    },

    _updateUnnamedElements(el)
    {
        let arr = this._getAllTextNodes(el);

        for(let num = arr.length - 1; num >= 0 ; num--)
        {
            if(arr[num].previousSibling && arr[num].previousSibling.getAttribute)
            {
                if(arr[num].previousSibling.getAttribute('class') == 'dict-numbers' || arr[num].previousSibling.getAttribute('class') == 'dict-curly')
                {
                    if(arr[num].nextSibling && arr[num].nextSibling.tagName && arr[num].nextSibling.tagName.toLocaleLowerCase() == 'br')
                    {
                        let el = document.createElement('span');
                        el.setAttribute('class','dict-example');
                        el.innerText = arr[num].nodeValue;
    
                        arr[num].parentNode.insertBefore(el,arr[num]);
                        arr[num].parentNode.removeChild(arr[num]);
                        // console.log(arr[num],"AA");
                    }
                }
            }
        }
    },

    _generateObject(el,temp)
    {
        let arr  = el.childNodes;
        let obj  = [];

        // console.log(arr,arr.length,temp);
        
        temp = temp ? temp : null;

        for(let num = 0; num < arr.length; num++)
        {
            // console.log(arr[num]);
            if(arr[num].getAttribute && arr[num].getAttribute('class'))
            {
                let className = arr[num].getAttribute('class');
                switch(className)
                {
                    case 'dict-roman-numbers':
                    case 'dict-numbers':
                    {
                        if(temp && temp.parent)
                        {
                            delete temp.parent;
                        }
                        temp = {};
                        obj.push(temp);
                        temp[(className == 'dict-numbers' ? 'number' : 'roman')] = arr[num].innerText;
                        break;
                    }
                    case 'dict-example':
                    {
                        if(!temp)
                        {
                            temp = {};
                            obj.push(temp);
                        }
                        // if(obj.indexOf(temp) == -1)
                        // {
                        //     obj.push(temp);
                        // }
                        let example = null;
                        // if(temp.parent)
                        // {
                        //     console.log("EX",temp.parent);
                        //     // temp.parent.example = {};
                        //     temp.example = {};
                        //     example = temp.example;
                        // }
                        // else
                        // {
                        //     temp.example = {};
                        //     example = temp.example;
                        // }
                        temp.example = {};
                        example = temp.example;
                        this._generateObject(arr[num],example);
                        break;
                    }
                    case 'dict-chinese-grammar':
                    {
                        if(!temp)
                        {
                            temp = {};
                            obj.push(temp);
                        }
                        // if(obj.indexOf(temp) == -1)
                        // {
                        //     obj.push(temp);
                        // }
                        if(!temp.chineseGrammar)
                        {
                            temp.chineseGrammar = [];
                        }
                        temp.chineseGrammar.push(arr[num].innerText);
                        break;   
                    }
                    case 'dict-see-also':
                    {
                        if(!temp)
                        {
                            temp = {};
                            obj.push(temp);
                        }

                        let type = arr[num].getAttribute('addType');
                        let prop = 'seeAlso';

                        if(type)
                        {

                            switch(type)
                            {
                                case 'lat'    :{prop = 'seeLat'    ; break;}
                                case 'example':{prop = 'seeExample'; break;}
                                case 'same'   :{prop = 'seeSame'   ; break;}
                                case 'also'   :{prop = 'alsoSame'  ; break;}
                                case 'alter'  :{prop = 'seeAlter'  ; break;}
                            }
                        }
                        
                        if(!temp[prop])
                        {
                            temp[prop] = [];
                        }
                        temp[prop].push(arr[num].innerText);
                        break;   
                    }
                    case 'dict-quad':
                    {
                        if(!temp)
                        {
                            temp = {};
                            obj.push(temp);
                        }
                        // if(obj.indexOf(temp) == -1)
                        // {
                        //     obj.push(temp);
                        // }
                        if(!temp.quadBrackets)
                        {
                            temp.quadBrackets = [];
                        }
                        temp.quadBrackets.push(arr[num].innerText);
                        break;   
                    }
                    case 'dict-curly':
                    {
                        if(!temp)
                        {
                            temp = {};
                            obj.push(temp);
                        }
                        // console.log("TEMP",temp);
                        // if(obj.indexOf(temp) == -1)
                        // {
                        //     obj.push(temp);
                        // }
                        if(!temp.curlyBrackets)
                        {
                            temp.curlyBrackets = [];
                        }
                        temp.curlyBrackets.push(arr[num].innerText);
                        break;
                    }
                    case 'dict-brackets':
                    {
                        if(!temp)
                        {
                            temp = {};
                            obj.push(temp);
                        }
                        // if(obj.indexOf(temp) == -1)
                        // {
                        //     obj.push(temp);
                        // }
                        if(!temp.chineseBrackets)
                        {
                            temp.chineseBrackets = [];
                        }
                        temp.chineseBrackets.push(arr[num].innerText);
                        break;
                    }
                    case 'dict-arrow':
                    {
                        if(!temp)
                        {
                            temp = {};
                            obj.push(temp);
                        }
                        // if(obj.indexOf(temp) == -1)
                        // {
                        //     obj.push(temp);
                        // }
                        if(!temp.arrowBrackets)
                        {
                            temp.arrowBrackets = [];
                        }
                        temp.arrowBrackets.push(arr[num].innerText);
                        break;
                    }
                }
            }
            else
            {
                if(arr[num].tagName && arr[num].tagName.toLowerCase() == 'br')
                {
                    let oldTemp = temp;
                    newTemp     = {parent:(oldTemp.parent ? oldTemp.parent : oldTemp)};
                    temp        = newTemp;
                    obj.push(temp);

                    if(oldTemp.parent)
                    {
                        delete oldTemp.parent;
                    }
                    
                }
                else
                {
                    if(!temp)
                    {
                        temp = {};
                        obj.push(temp);
                    }
                    
                    let oldTemp = temp;
                    newTemp     = {parent:oldTemp};
                    // newTemp     = {parent:(oldTemp.parent ? oldTemp.parent : oldTemp)};
                    temp        = newTemp;
                    temp.text   = arr[num].nodeValue;

                    if(oldTemp.parent)
                    {
                        delete oldTemp.parent;
                    }
                    if(!temp.parent.texts)
                    {
                        temp.parent.texts = [];
                    }
                    temp.parent.texts.push(temp);
                }
            }
        }
        
        if(temp.parent)
        {
            delete temp.parent;
        }

        return obj;
    },

    _flattenText(el)
    {
        this._updateToDictExample(el,'b',['dict-chinese-grammar','dict-angle-brackets','dict-numbers']);
        this._addPadding(el,['dict-angle-brackets','dict-chinese-grammar'],'dict-example');
        let ex = el.getElementsByClassName('dict-example');

        for(let num1 = 0; num1 < ex.length; num1++)
        {
            for(let num2 = 0; num2 < ex[num1].childNodes.length; num2++)
            {
                if(ex[num1].childNodes[num2].getAttribute)
                {
                    this._replaceWithBBCode(ex[num1].childNodes[num2]);
                }
            }
            ex[num1].innerHTML = ex[num1].innerText;
        }
        this._updateLinks(el);

        this._mergeTextNodes(el);
        this._mergeTextNodes(el,['dict-example']);
    },

    _updateLinks(el)
    {
        let links = el.getElementsByClassName('dict-see-also');

        for(let num = 0; num < links.length; num++)
        {
            this._replaceWithBBCode(links[num]);
        }
    },

    _updateToDictExample(el,tag,classes)
    {
        let els = el.getElementsByTagName(tag);

        for(let num = 0; num < els.length; num++)
        {
            if(els[num].childNodes.length>0 && els[num].childNodes[0].tagName == 'I')
            {
                let prev = els[num].previousSibling.nodeValue;
                let next = els[num].nextSibling.nodeValue;

                if(prev)
                {
                    prev = prev.trim();
                }
                if(next)
                {
                    next = next.trim();
                }
                if(prev && prev.length>0 && prev[prev.length-1] == '[' && next && next.length>0 && next[0] == ']')
                {
                    next = (next.length == 1) ? '' : next.substring(1,next.length-1);
                    prev = (prev.length == 1) ? '' : prev.substring(0,prev.length-2);

                    els[num].previousSibling.nodeValue = prev;
                    els[num].nextSibling.nodeValue     = next;

                    if(prev.length == 0)
                    {
                        els[num].parentNode.removeChild(els[num].previousSibling);
                    }
                    if(next.length == 0)
                    {
                        els[num].parentNode.removeChild(els[num].nextSibling);
                    }
                    
                    els[num].childNodes[0].prepend(document.createTextNode('['));
                    els[num].childNodes[0].append (document.createTextNode(']'));

                    els[num].childNodes[0].innerHTML.split('br' ).join(' ');

                    let prevSib = els[num].previousSibling;
                    
                    if(prevSib && prevSib.tagName && prevSib.tagName == 'BR')
                    {
                        prevSib = prevSib.previousSibling;
                    }
                    if(prevSib && prevSib.getAttribute && classes.indexOf(prevSib.getAttribute('class'))>-1)
                    {
                        els[num].setAttribute('class','dict-example');
                    }
                }
            }
        }
    },

    _addPadding(el,targets,nextSibling)
    {
        for(let num1 = 0; num1 < targets.length; num1++)
        {
            let targEls = el.getElementsByClassName(targets[num1]);

            for(let num2 = 0; num2 < targEls.length; num2++)
            {
                if(targEls[num2].previousSibling && targEls[num2].previousSibling.nodeValue && targEls[num2].nextSibling && targEls[num2].nextSibling.getAttribute && targEls[num2].nextSibling.getAttribute('class') == nextSibling)
                {
                    targEls[num2].parentNode.insertBefore(document.createElement('br'),targEls[num2]);
                }
            }
        }
    },

    _sanitizeTexts(el)
    {
        let arr = this._getAllTextNodes(el);

        for(let num = arr.length - 1; num >= 0; num--)
        {
            // arr[num].nodeValue = arr[num].nodeValue.split('br' ).join(' ');
            arr[num].nodeValue = arr[num].nodeValue.split('///').join( '');
            arr[num].nodeValue = arr[num].nodeValue.split('//' ).join( '');
            arr[num].nodeValue = arr[num].nodeValue.toString().trim();
        }
    },

    _mergeTextNodes(el,reversedElements)
    {
        reversedElements = !reversedElements ? [] : reversedElements;

        let nodes  = Array.from(el.childNodes);
        let node   = null;
        for(let num = nodes.length; num >= 0; num--)
        {
            if(nodes[num])
            {
                if(nodes[num].tagName || (nodes[num].tagName && nodes[num].tagName.toLowerCase() == 'br'))
                {
                    node = null;
                }
                if(!nodes[num].tagName && nodes[num].nodeValue.length>0)
                {
                    if(node)
                    {
                        let str = node.nodeValue ? node.nodeValue : node.innerText;
                        if(node.getAttribute && reversedElements.indexOf(node.getAttribute('class'))>-1)
                        {
                            node.innerText = nodes[num].nodeValue+" "+node.innerText;
                            
                            node.parentNode.removeChild(nodes[num]);
                        }
                        else
                        {
                            nodes[num].nodeValue+=" "+str;
                            
                            node.parentNode.removeChild(node);
    
                            node = nodes[num];
                        }
                    }
                    else
                    {
                        node = nodes[num];
                    }
                }
                else if(nodes[num].tagName && nodes[num].innerText.length>0)
                {
                    nodes[num] = this._replaceWithBBCode(nodes[num]);

                    if(node)
                    {
                        let str = node.nodeValue ? node.nodeValue : node.innerText;

                        nodes[num].innerText+=" "+str;
                        
                        node.parentNode.removeChild(node);

                        node = nodes[num];

                    }
                    else
                    {
                        node = nodes[num];
                    }
                }
            }
        }
    },

    _cleanLinkElement(node)
    {
        let obj = {tag:'',prop:''};
        let sib = (node.previousSibling && node.previousSibling.nodeValue && (node.previousSibling.nodeValue.trim().length == 0 || node.previousSibling.nodeValue.trim() == 'ანტ.') ? node.previousSibling.previousSibling : node.previousSibling);
        let add = '';

        let els = node.getElementsByTagName('a');
        if(els.length>0 && els[0].getAttribute('href'))
        {
            let attr = els[0].getAttribute('href');
            if(attr.indexOf('http://')>-1 || attr.indexOf('https://')>-1)
            {
                obj.prop = attr;
            }
        }
        if(node.previousSibling != sib && node.previousSibling.nodeValue.trim().length>0)
        {
            add = node.previousSibling.nodeValue.trim();
        }
        if(sib && sib.getAttribute && sib.getAttribute('class') == 'dict-italic')
        {
            obj.tag = 'link';
            add = add.length == 0 ? sib.innerText.trim() : add;
            
            switch(add)
            {
                case 'იგივეა, რაც':{obj.tag = 'same-as-link'; break;}
                case 'ასევე'       :{obj.tag = 'see-also-link'; break;}
                case 'იხ.'        :{obj.tag = 'see-link';      break;}
                case 'ანტ.'       :{obj.tag = 'see-ant-link';  break;}
            }

            if(sib.previousSibling && sib.previousSibling.nodeValue)
            {
                let val = sib.previousSibling.nodeValue.trim();

                if(val[val.length-1] == '(')
                {
                    val = val.substring(0,val.length-2).trim();
                    
                    sib.previousSibling.nodeValue = val;
                }
                node.parentNode.removeChild(sib);
            }
            if(node.nextSibling && node.nextSibling.nodeValue && node.nextSibling.nodeValue.trim() == ')')
            {
                node.parentNode.removeChild(node.nextSibling);
            }
        }
        return obj;
    },

    _replaceWithBBCode(node)
    {
        let str = '';
        
        if(node.getAttribute('class') == 'dict-see-also')
        {
            let obj = this._cleanLinkElement(node);
            
            if(obj.tag.length>0)
            {
                node.innerText = '['+obj.tag+(obj.prop.length>0 ? ' href="'+obj.prop+'"' : '') + ']' + node.innerText.replace('['+obj.tag+(obj.prop.length>0 ? ' link="'+obj.prop+'"' : '') + ']','').replace('[/'+obj.tag+']','').replace('[link]','').replace('[/link]','') + '[/'+obj.tag+']';
            }
        }
        if(node.getAttribute('class') == 'dict-example')
        {
            node.innerText = '[example]' + node.innerText.replace('[example]','').replace('[/example]','') + '[/example]';
        }
        if(node.getAttribute('class') == 'dict-numbers')
        {
            node.innerText = '[number]' + node.innerText.replace(')','').replace('[number]','').replace('[/number]','') + '[/number]';
        }
        if(node.getAttribute('class') == 'dict-roman-numbers')
        {
            node.innerText = '[roman-number]' + node.innerText.replace('[roman-number]','').replace('[/roman-number]','') + '[/roman-number]';
        }
        if(node.getAttribute('class') == 'dict-chinese-brackets')
        {
            str = '[ch-brackets]' + node.innerText.replace('【','').replace('】','') + '[/ch-brackets]';
        }
        if(node.getAttribute('class') == 'dict-angle-brackets')
        {
            str = '[angle]' + node.innerText.replace('〈','').replace('〉','') + '[/angle]';
        }
        if(node.getAttribute('class') == 'dict-curly-brackets')
        {
            str = '[curly]' + node.innerText.replace('{','').replace('}','') + '[/curly]';
        }
        if(node.getAttribute('class') == 'dict-chinese-grammar')
        {
            str = '[ch-grammar]' + node.innerText + '[/ch-grammar]';
        }

        if(str.length>0)
        {
            let text = document.createTextNode(str);
            node.parentNode.insertBefore(text,node);
            node.parentNode.removeChild(node);

            return text;
        }
        return node;
    },

    _sanitizeLinks(el)
    {
        let links = el.getElementsByTagName('a');
        
        for(let num = links.length-1; num >= 0; num--)
        {
            if(links[num].parentNode.getAttribute('class') == 'dict-see-also')
            {
                let parent = links[num].parentNode.parentNode;
                for(let num2 = parent.childNodes.length-1; num2 >=0 ; num2--)
                {
                    let node = parent.childNodes[num2];
                    if(node.getAttribute && node.getAttribute('class') && node.getAttribute('class') == 'dict-italic')
                    {
                        let classAppend = '';
                        
                        switch(node.innerText)
                        {
                            case 'ლათ.'      :{classAppend = 'lat'    ; break;}
                            case 'მაგ.'       :{classAppend = 'example'; break;}
                            case 'იგივეა, რაც':{classAppend = 'same'   ; break;}
                            case 'ასევე'      :{classAppend = 'also'    ; break;}
                            case 'გადატ.'    :{classAppend = 'alter'   ; break;}
                        }

                        if(classAppend.length>0)
                        {
                            links[num].parentNode.setAttribute('addType',classAppend);
                        }
                        // console.log(classAppend,node.innerText,links[num].parentNode);
                        parent.removeChild(node);
                        break;
                    }
                }
                links[num].parentNode.innerHTML = links[num].innerHTML;
            }
        }
    },

    parseDomToObject(dom)
    {
        let arr = [];
        let el  = document.createElement('div');
        el.innerHTML = dom;

        return this._sanitizeDom(el);
    },

    parseDomToString(dom)
    {
        let el  = document.createElement('div');
        el.innerHTML = dom;

        let arr = this._sanitizeDom(el);

        let str = '';

        for(let num1 = 0; num1 < arr.length; num1++)
        {
            str+='\n';

            let obj       = arr[num1];
            let isCounter = false;

            if(obj.number)
            {
                str+=obj.number + " ";
                isCounter = true;
            }
            if(obj.roman)
            {
                str+=obj.roman + " ";
                isCounter = true;
            }
            if(obj.example)
            {
                isCounter = false;
                for(let num2 = 0; num2 < obj.example.texts.length; num2++)
                {
                    str+=obj.example.texts[num2].text;
                }
            }

            if(!isCounter)
            {
                str+='\n';
            }
            if(obj.texts)
            {
                for(let num2 = 0; num2 < obj.texts.length; num2++)
                {
                    let prefix = (isCounter ? '' : '[bullet]' );
                    let suffix = (isCounter ? '' : '[/bullet]');
                    str+=(prefix + obj.texts[num2].text + suffix);
                }
            }
        }

        let arrNew = str.split('\n');
        let strNew = '';

        for(let num = 0; num < arrNew.length; num++)
        {
            if(arrNew[num].length>0)
            {
                strNew+=arrNew[num]+'\n';
            }
        }
        return strNew;
    },

    prepareRow:function(dom)
    {
        dom = dom.split('<br>').join('');

        let el    = document.createElement('div');
        let el2   = document.createElement('div');

        let left  = document.createElement('div');
        let right = document.createElement('div');

        left .setAttribute('class','left' );
        right.setAttribute('class','right');

        el2.appendChild(left);
        el2.appendChild(right);
        
        el.innerHTML = dom;

        let selected = left;
        let arr      = Array.from(el.childNodes);
        for(let num = 0; num < arr.length; num++)
        {
            let node = arr[num];
            
            if(!node.getAttribute)
            {
                selected = right;
            }
            selected.appendChild(node);
        }
        

        return el2.innerHTML;
    },

    updareRows(rows)
    {
        let elRows = [];
        let max    = 0;
        for(let num = 0; num < rows.length; num++)
        {
            let el = document.createElement('div');
            el.innerHTML = rows[num];
            
            elRows.push(el);
            if(el.childNodes[0].childNodes.length>max)
            {
                max = el.childNodes[0].childNodes.length;
            }
        }

        for(let num = 0; num < elRows.length; num++)
        {
            elRows[num].childNodes[0].classList.add('count_'+max);
            rows[num] = elRows[num].innerHTML;
        }

        return rows;
    }
};

try 
{
    module.exports = DomParser;
}
catch (e){}