<template>
  <div class="viewCont">
    <LandingView    class="landingView"    v-bind:backgrounds  ="data.data.landingView.background"
                                           v-bind:bgIndex      ="bgIndex"
                                           v-bind:searchResults="data.search"
                                           v-on:action="onSearch" />
    <DedicationView class="dedicationView" v-bind:backgrounds="data.data.dedication.background"
                                           v-bind:bgIndex    ="bgIndex" />
    <DictionaryView class="dictionaryView" v-bind:backgrounds="data.data.dedication.background"
                                           v-bind:bgIndex    ="bgIndex"
                                           v-bind:wordCount  ="data.data.dictionary.wordCount" />
    <OurTeamView    class="outTeamView"    v-bind:data="data.data.ourTeams"/>
    <MissionView    class="missionView"    v-bind:data="data.data.teamValues"/>
  </div>
</template>
<script>
import LandingView    from '@/components/MainView/LandingView.vue';
import DedicationView from '@/components/MainView/DedicationView.vue';
import DictionaryView from '@/components/MainView/DictionaryView.vue';
import OurTeamView    from '@/components/MainView/OurTeamView.vue';
import MissionView    from '@/components/MainView/MissionView.vue';
import BaseComponent  from '@/components/base/BaseComponent.vue';

export default {
  name: 'MainPage',
  extends:BaseComponent,
  components: 
  {
    LandingView,
    DedicationView,
    DictionaryView,
    OurTeamView,
    MissionView
  },
  methods:
  {
    onSearch:function(type,data)
    {
      switch (type)
      {
        case 'onInput'       :{this.onAction('onSearch'       ,data); break;}
        case 'onWordInput'   :{this.onAction('onWordSearch'   ,data); break;}
        case 'onArticleInput':{this.onAction('onArticleSearch',data); break;}
        case 'onItem'        :{this.onAction('onSearchItem'   ,data); break;}
      }
    },
    scroll:function(val)
    {
      let view   = this.data.data[this.$route.name];
      let scroll = view && view.scroll ? window.innerHeight + view.scroll : 0;

      window.scrollTo({
        top: scroll,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  watch:
  {
    '$route'()
    {
      this.scroll();
    }
  },
  data: function ()
  {
    return {
      bgIndex:0
    }
  },
  props:
  {
    data  :Object
  },
  mounted:function()
  {
      this.scroll();
  },
  created:function()
  {
      this.bgIndex = Math.floor(Math.random() * Math.floor(this.data.data.landingView.background.length));
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
  // margin-top:90px;

  .landingView
  {
    min-height: 100vh;
    // min-height: calc(100vh - 90px);
  }
}

@media only screen and (max-width: 1000px)
{
  /deep/ .titleCont 
  {
    padding-left: 4% !important;
    width: 90% !important;

    .title
    {
      width: 100% !important;
    }

    .stroke
    {
          margin-left:6% !important;
    }
  }
  
  /deep/ .dedicationViewCont
  {
    height  : 60vh;

    .centerCont
    {
      flex-direction: column;
      justify-content: center;

      .photo
      {
        background-position: 50% 60% !important;
        width: 80% !important;
        height: 60% !important;
      }

      .text_ge
      {
        background-position: 50% 10% !important;
        width: 95% !important;
        height: 40% !important;
      }
    }
  }

  /deep/ .dictionaryView
  {
    height  : 70vh;

    .content
    {
      font-size: 0.7rem !important;
      width: 90% !important;
    }

    .counterCont
    {
      .title
      {
        font-size: 10px !important;
        margin-bottom: 10px;
      }
      .counter
      {
        font-size: 50px !important;
      }
    }
  }

  /deep/ .outTeamView
  {
    // min-height  : 100vh;
    height: unset;
    background-color:transparent;
    // background-image: url('./../assets/img/backgrounds/stripe.png');

    .articleCont
    {
      position: relative !important;
      
      .centerCont
      {
        display: block !important;
        background-image: none !important;

        .mainAuthor
        {
          padding-top: 0px;
          margin-top: 10px;
          width: 100% !important;
          height: 200px !important;
          margin-bottom: 20px;
        }

        .content
        {
          justify-content: center !important;
          flex-flow: wrap !important;
          width: 100% !important;
          height: unset !important;

          .info
          {
            width: 40% !important;
            text-align: center;

            .position
            {
              font-size: 0.8rem !important;
            }

            .names
            {
              font-size: 0.7rem !important;
            }
          }
        }
      }
    }
  }

  /deep/ .missionView
  {
    height  : unset;
    .values
    {
      top: 10px !important;
      flex-direction: column;
      align-items: center;
      height: unset !important;

      .value
      {
        width: 90%;
        margin-bottom: 30px !important;
      }
    }

    .textCont
    {
      margin-top: 50px !important;
      min-width: 80% !important;
      width: 80% !important;
      margin-bottom: 20px;

      .stroke
      {
          margin-left: 70% !important;
      }
    }
  }
}
</style>
