<template>
  <div class="FooterCont">
    <div class="cont">
      <div class="notice" v-html="getWord(data.notice)"></div>
      <div class="date"   v-html="getWord(data.date)+year"></div>
    </div>
  </div>
</template>

<script>
import BaseComponent from './base/BaseComponent.vue';

export default {
  name: 'Footer',
  extends:BaseComponent,
  components: 
  {
  },
  computed:
  {
    year:function()
    {
      if(!this.date)
      {
        this.date = new Date();
      }
      return this.date.getFullYear();
    }
  },
  data:function()
  {
    return {
      date:null
    }
  },
  props:
  {
    data:Object
  }
}
</script>
<style scoped lang="scss">
.FooterCont
{
  position: relative;
  width: 100%;
  height: 55px;
  background-color: #dddcdc;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  
  text-align: center;
  font-feature-settings: normal;
  font-style: italic;
  font-size: 18px;
  flex-shrink: 0;

  .cont
  {
    .notice
    {
      font-size: 10px;
    }

    .date
    {
      font-style: normal;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .FooterCont
  {
    font-size: 12px;
    
    .cont
    {
      .notice
      {
        font-size: 8px;
      }

      .date
      {
        font-size: 10px;
      }
    }
  }
}
</style>
