<template>
  <div class="viewCont">
    <div class="viewCont-Content">
      <div class="grids">
        <div class="cont" v-for="block in data" v-bind:key="'block_'+block.img">
          <div class="image" v-bind:style="{backgroundImage:'url('+block.img+')'}">
            <div class="title">{{block.name.ge}}</div>
          </div>
          <div class="content">
            <div class="item" v-for="(item) in block.dates" v-bind:key="'item_'+item.name">
              <div class="cell first" >{{item.name}}</div>
              <div class="cell second">{{item.date}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'Seasons',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  methods:
  {
    scroll:function()
    {
      let scroll = this.$route.params.id ? this.data[this.$route.params.id].pos : 0;
      
      window.scrollTo({
        top: scroll,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  watch:
  {
    '$route'()
    {
      this.scroll();
    }
  },
  mounted:function()
  {
    this.scroll();
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 96%;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  .viewCont-Content
  {
    margin-top: 100px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;

    .grids
    {
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      align-items:center;
      max-width:1500px;

      .cont
      {
        margin-top: 50px;
        position: relative;
        width: 500px;
        height: auto;
        display: flex;
        
        margin-left: 2%; 
        margin-right: 2%;

        .content
        {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          font-feature-settings: initial;

          font-size: 10px;
          text-align: left;

          .item
          {
            display: flex;
            border-bottom: solid 1px #dedede;
            min-width:370px;
            height:21.5px;
            margin-left:10px;
            line-height:21.5px;

            .cell
            {
              display: inline-flex;
              flex-flow: column;
              justify-content: center;
              width: 50%;
            }

            .first
            {
              width:65%;
            }

            .second
            {
              width:35%;
            }
          }
        }

        .image
        {
          flex-shrink: 0;
          width:130px;
          height:130px;
          background-position:center center;
          background-repeat:no-repeat;
          background-size:cover;
          border: solid 1.5px #000000;

          .title
          {
            background-color:#ffce00;
            width:86px;
            height:18px;
            margin-top:120px;
            margin-left:22px;
            font-size:13.5px;
            text-align:center;
            color:#ffffff;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .viewCont
  {
    width: 96% !important;
    margin-left: 2% !important;

    .viewCont-Content
    {
      width: 100% !important;
      margin-top: 50px !important;

      .cont
      {
        width: 100% !important;
        margin: 0 !important;
        margin-bottom: 30px !important;
        flex-direction: column !important;

        .image
        {
          margin-left: auto !important;
          margin-right: auto !important;
          margin-bottom: 20px !important;
          // width: 50px !important;
          // height: 50px !important;
        }

        .content
        {
          .item
          {
            min-width: unset !important;
            line-height: unset !important;
            height: unset !important;

            .cell
            {
              width: 48% !important;
            }

            .second
            {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>
