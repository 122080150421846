export default class LanguageManager
{
    constructor(cb)
    {
        this._languages = null;
        this._selected  = '';
        this._getData('./languages/languages.json','GET',(data)=>
        {
            this._languages = JSON.parse(data);
            if(cb)
            {
                cb();
            }
        });
    }
    
    ////////////////////////////////////
    _getData(url,type,onComplete,params)
    {
        type   = type   == undefined ? "GET" : type;
        params = params == undefined ? {}    : params;

        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function()
        {
            if (this.readyState == 4)
            {
                if(this.status == 200)
                {
                    if(onComplete)
                    {
                        onComplete(this.responseText);
                    }
                }
                if(this.status == 400)
                {
                    if(onComplete)
                    {
                        onComplete('[]');
                    }
                }
            }
        };
        xhttp.open(type, url, true);
        xhttp.setRequestHeader('Content-Type', 'application/json');
        xhttp.send(params);
    }

    getWord(val)
    {
        if(!this._languages[this._selected])
        {
            return val;
        }
        
        if(this._languages[this._selected][val])
        {
            return this._languages[this._selected][val];
        }

        if(val.indexOf('.'))
        {
            let arr   = val.split('.');
            let item  = this._languages[this._selected];
            for(let num = 0; num < arr.length; num++)
            {
                if(item[arr[num]])
                {
                    if(num == arr.length-1)
                    {
                        return item[arr[num]];
                    }
                    item = item[arr[num]];
                }
            }
        }
        return val;
    }

    set language(val)
    {
        if(this._languages[val])
        {
            this._selected = val;
        }
    }

    get language()
    {
        return this._selected;
    }
}