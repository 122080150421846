<template>
  <div class="contactCont">
    <div class="formCont">
      <div class="form">
        <div class="title">{{getWord('contact')}}</div>
        <div class="field">
          <div class="label">{{getWord('firstName')+getWord('lastName')+' / '+getWord('subject')}}</div>
          <input class="input" v-model="formData.name" type="text" />
        </div>
        <div class="field">
          <div class="label">{{getWord('email')}}</div>
          <input class="input" v-model="formData.email" type="text" />
        </div>
        <div class="field">
          <div class="label">{{getWord('textContent')}}</div>
          <textarea class="input big" v-model="formData.text" type="text" />
        </div>
        <div class="bottomField">
          <div class="mail"  ><!--{{data.general.email}}--></div>
          <div class="button" v-on:click="send()">{{getWord('send')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseComponent from '@/components/base/BaseComponent.vue';

export default 
{
  name: 'Contact',
  extends:BaseComponent,
  data:function()
  {
    return {
      formData:
      {
        name :'',
        email:'', 
        text :''
      }
    }
  },
  props:
  {
    data:Object
  },
  methods:
  {
    send:function()
    {
      this.onAction('sendMail',this.formData);
    }
  },
  mounted:function()
  {
    this.formData.name  = 
    this.formData.email =
    this.formData.text  = '';
  }
}
</script>
<style scoped lang="scss">
.contactCont
{
  background-color: #f4f3f2;
  width: 100%;
  height: calc(100vh - 55px);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  .formCont
  {
    background-image: url('./../assets/img/backgrounds/stripe.png');
    width: 100%;
    height:380px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    .form
    {
      width: 540px;
      height: 80%;

      .title
      {
        text-align: center;
        font-size:24px;
        font-weight: bold;
      }

      .field
      {
        margin-top: 10px;

        .label
        {
          color: #666666;
          font-feature-settings: normal;
          font-size: 10px;
        }

        .input
        {
          margin-top: 10px !important;
          background-color: #f9f7f7;
          width: calc(100% - 20px);
          padding-left: 20px;
          height: 26px;
          border-radius: 3px;
          outline: none;
          border:none;
          font-size: 16px;
          box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3);
          
          &:focus
          {
            outline: none;
          }
        }

        .big
        {
          height: 50px;
          resize: none;
        }
      }

      .bottomField
      {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .mail
        {
          color: #666666;
          font-feature-settings: normal;
          font-size: 12px;
          line-height: 24px;
          font-style: italic;
        }

        .button
        {
          background-color: #ffce00;
          color: #ffffff;
          text-align: center;
          border-radius: 5px;
          line-height: 34px;
          width: 90px;
          height: 34px;
          font-size: 14px;
          box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3);
          cursor: pointer;
          transition: all .5s;

          &:hover
          {
            background-color: #dcb326;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .formCont
  {
    .form
    {
      width: 90% !important;
    }
  }
}
</style>
