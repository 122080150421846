<template>
  <div class="viewCont">
    <ArticleCont class="cont" v-bind:strokePadding="0" v-bind:strokeSize="64" v-bind:title="data.title.ge" v-bind:title2="data.title.ch">
      <div class="content">
        <div class="gridBody">
          <div class="item" v-for="(item, index) in data.list" v-bind:key="'item_'+index">
            <div class="cell">
              <div class="title ge">{{item.ge}}</div>
              <div class="title ch">{{item.ch}}</div>
            </div>
            <a class="cell icon icon-download" target="blank" v-bind:href="item.path"></a>
          </div>
        </div>
      </div>
    </ArticleCont>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'Translations',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  mounted:function()
  {
     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 100%;
  height: auto;

  .cont
  {
    position: relative;
    margin-top: 80px;

    .content
    {
      width: 80%;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      font-feature-settings: initial;
      border-top: solid 1px #333;

      .gridBody
      {
        margin-top:5px;
        margin-bottom: 50px;
        font-size: 14px;
        text-align: left;

        .item
        {
          display: flex;
          background-color: #f4ebca;
          margin-bottom: 5px;
          height: 40px;
          align-items: center;
          justify-content: space-between;

          .cell
          {
            padding-left: 5px;
            padding-right: 5px;

            &.icon
            {
              width: 15px;
              height: 15px;
              cursor: pointer;
              transition: all .5s;

              &:hover
              {
                opacity: 0.5;
              }
            }

            .title
            {
              font-size: 12px;

              &.ge
              {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1000px)
{
  .articleCont
  {
    margin-bottom: 10px !important;
    /deep/ .titleCont
    {
        overflow: hidden;
        font-size: 16px !important;
        width: 90% !important;
        margin-top: 0px !important;
        margin-right: 0% !important;
        margin-left: 0% !important;
        padding-left: 5% !important;
        padding-top: 0% !important;
    }

    .container
    {
      .content
      {
        margin-left: 5% !important;
        width: 90% !important;
        
        .gridBody
        {
          font-size: 10px !important;
            overflow: hidden;
            line-break: anywhere;
            padding:0 !important;
        }
      }
    }
  }
}
</style>
