<template>
  <div class="landingViewCont">
    <Background class="background" v-bind:index="bgIndex" v-bind:backgrounds="backgrounds" >
      <div class="searchCont">
        <InputDropdown class="dropdown" v-bind:noItemsText1="noItemsText1" v-bind:noItemsText2="noItemsText2" v-bind:items="searchResults" v-on:action="onAction" v-bind:offsetWhenItemsVisible="-90" />
      </div>
    </Background>
  </div>
</template>

<script>
import Background    from './../elements/Background.vue'
import InputDropdown from './../elements/InputDropdown.vue'
import BaseComponent from './../base/BaseComponent.vue'

export default {
  name: 'LandingView',
  extends:BaseComponent,
  components: 
  {
    Background,
    InputDropdown
  },
  props:
  {
    searchResults:Array,
    backgrounds:Array,
    bgIndex:Number,
    noItemsText1:String,
    noItemsText2:String
  }
}
</script>
<style scoped lang="scss">
.landingViewCont
{
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  
  .background
  {
    width: 100%;
    height: 100%;

    .searchCont
    {
      margin-left: auto;
      margin-right: auto;
      margin-top: 30vh;
      max-width: 490px;
      min-width: 100px;
      display: block;

      .dropdown
      {
        margin-left: 10px;
        width: calc(100% - 20px);
      }
    }
  }
}
</style>
