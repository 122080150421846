<template>
  <div class="viewCont">
    <ArticleCont class="cont" v-for="item in data.items" v-bind:key="item.title.ge" v-bind:strokePadding="0" v-bind:strokeSize="64" v-bind:title="item.title.ge" v-bind:title2="item.title.ch">
      <div class="contentData" v-html="item.text"></div>
    </ArticleCont>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'About',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  methods:
  {
    scroll:function()
    {
      let scroll = this.$route.params.id ? this.data[this.$route.params.id].pos : 0;
      
      window.scrollTo({
        top: scroll,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  watch:
  {
    '$route'()
    {
      this.scroll();
    }
  },
  mounted:function()
  {
    this.scroll();
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 96%;
  height: auto;
  margin-top:100px;
  margin-bottom:100px;
  margin-left: auto;
  margin-right: auto;
  font-size:12px;

  .cont
  {
    position:relative;
    
    .contentData
    {
      width:calc(100% - 150px);
      margin-top:40px;
      margin-left:75px;
      font-feature-settings:initial;
      
      /deep/
      .boldText
      {
        font-weight:bold;
        font-feature-settings: "aalt";
      }
      
      /deep/
      .greenblock
      {
        color:#4da6cd;
      }

      /deep/
      .greenChar
      {
        color:#588e67;
      }

      /deep/
      .greenChar2
      {
        color:#2c7873;
        font-weight:bold;
      }

      /deep/
      .orangeChar
      {
        color:#fe913f;
      }

      /deep/
      .deepOrangeChar
      {
        color:#a54a4d;
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .articleCont
  {
    margin-bottom: 10px !important;
    /deep/ .titleCont
    {
        overflow: hidden;
        font-size: 16px !important;
        width: 90% !important;
        margin-top: 0px !important;
        margin-right: 0% !important;
        margin-left: 0% !important;
        padding-left: 5% !important;
        padding-top: 0% !important;
    }

    .container
    {
      .contentData
      {
        margin-left: 5% !important;
        width: 90% !important;
      }
    }
  }
}
</style>
