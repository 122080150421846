<template>
  <div class="viewCont">
    <div class="searchCont">
      <InputDropdown class="dropdown" v-bind:items="data.search" v-on:action="onSearch"  v-bind:offsetWhenItemsVisible="-40" />
    </div>
    <div class="cont">
      <div class="wordCont">
        <div class="left">
          <div class="bigWordCont">
            <div class="word">{{data.article.word}}</div>
            <div class="sound-icon" v-bind:style="{height:zoomValue(),width:zoomValue()}" v-if="showVoice" v-on:click="playText(data.article.word,(playSlow ? 0.5 : 1))"></div>
          </div>
          <div class="transcriptions">
            <div class="item" v-for="item in transcriptions" v-bind:key="item">
              <div class="item-text"  v-bind:style="{height:zoomValue(),fontSize:zoomValue()}">{{item}}</div>
              <!-- <div class="sound-icon" v-bind:style="{height:zoomValue(),width:zoomValue()}" v-if="showVoice" v-on:click="playText(item,1)"></div> -->
            </div>
          </div>
          <div class="traditional"  >{{data.article.traditional  }}</div>
        </div>
        <div class="right">
          <div v-bind:class="['staticView',(drawerVisible == true ? 'hiddenIcon' : ''),(this.data.article.word.length == 1 ? '' : 'hidden')]" v-on:click="showDrawer(true)">
            <div class="staticChar" ref="drawer"></div>
            <div class="playIcon"></div>
          </div>
          <div v-bind:class="['animatedCharFrame',(drawerVisible == true ? '' : 'hiddenFrame')]">
            <div class="close" v-on:click="showDrawer(false)"></div>
            <div class="animatedChar" ref="drawerAnim" v-on:click="animateChar()">
          </div>
        </div>
      </div>
      </div>
      <div class="transCont">
        <div class="translation" v-html="data.article.translation" v-on:click="onInsideHTMLClick" v-bind:style="{fontSize:zoomValue()}"></div>
        <div v-bind:class="['picFrame',(selectedPic.show ? '' : 'hiddenFrame')]">
            <div class="close" v-on:click="removePicture()"></div>
            <div class="picture" v-bind:style="{backgroundImage:'url('+selectedPic.url+')'}"></div>
        </div>
        <div class="zoomBlock">
          <div class="labelCont">
            <div class="label">{{getWord('fontSize')}}</div>
          </div>
          <div v-bind:class="['btn',(isMaxZoom() ? 'disabledBtn' : '')]" v-on:click="onZoom(+1)"><div class="label">+</div></div>
          <div v-bind:class="['btn',(isMinZoom() ? 'disabledBtn' : '')]" v-on:click="onZoom(-1)"><div class="label">-</div></div>
        </div>
      </div>
      <div class="feedback" v-on:click="onContact()">
        <div class="mail-icon"></div>
        <div class="label">{{getWord('remarks')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import HanziWriter   from 'hanzi-writer';
import BaseComponent from '@/components/base/BaseComponent.vue';
import InputDropdown from '@/components/elements/InputDropdown.vue';

export default
{
  name: 'Article',
  extends:BaseComponent,
  components: 
  {
    InputDropdown
  },
  props:
  {
    data:Object
  },
  data:function()
  {
    return {
      transcriptions :[],
      drawerVisible  :false,
      writer         :null,
      writerAnim     :null,
      showVoice      :false,
      playSlow       :false,
      selectedPic    :{show:false,url:''}
    }
  },
  methods:
  {
    onContact:function()
    {
      this.onAction('onMenuButton',{path:'/contact'});
    },
    onSearch:function(type,data)
    {
      switch (type)
      {
        case 'onInput'       :{this.onAction('onSearch'       ,data); break;}
        case 'onWordInput'   :{this.onAction('onWordSearch'   ,data); break;}
        case 'onArticleInput':{this.onAction('onArticleSearch',data); break;}
        case 'onItem'        :{this.onAction('onSearchItem'   ,data); break;}
      }
    },
    onZoom:function(val)
    {
      this.onAction('onZoom',val);
    },
    onInsideHTMLClick:function(e)
    {
      if(e.target)
      {
        if(e.target.getAttribute('picture'))
        {
          let picID = e.target.getAttribute('picture');

          if(this.data.article.pictures[picID])
          {
            this.selectedPic.show = true;
            this.selectedPic.url  = this.data.article.pictures[picID];
          }
        }
      }
    },
    removePicture:function()
    {
        this.selectedPic.show = false;
    },
    playText:function(text,rate)
    {
      this.playSlow = !this.playSlow;

      rate = rate !== undefined ? rate : 1;

      let lang       = 'zh-CN';
      let utterance  = new self.SpeechSynthesisUtterance(text);
      let voices     = self.speechSynthesis.getVoices();

      utterance.rate = rate;

      for(let num = 0; num < voices.length; num++)
      {
        if(voices[num].lang == lang && voices[num].localService == false)
        {
          utterance.voice = voices[num];
          break;
        }
      }
      
      utterance.lang = lang;

      self.speechSynthesis.speak(utterance);
    },
    showDrawer:function(show)
    {
      this.drawerVisible = show;
    },
    animateChar:function()
    {
      if(this.writerAnim)
      {
        this.writerAnim.animateCharacter();
      }
    },
    updateVoicActive:function()
    {
      this.showVoice = false;

      setTimeout(()=>
      {
        let lang   = 'zh-CN';
        let voices = self.speechSynthesis.getVoices();

        if(voices.length == 0)
        {
          this.updateVoicActive();
        }
        for(let num = 0; num < voices.length; num++)
        {
          if(voices[num].lang == lang && voices[num].localService == false)
          {
            this.showVoice = true;
            break;
          }
        }
      },1);
    },
    updateChars:function()
    {
      this.drawerVisible  = false;
      this.transcriptions = this.data.article.transcription.length>0 ?  this.data.article.transcription.split(' ').join('').split(';') : [];
      if(this.data.article.word && this.data.article.word.length == 1)
      {
        if(this.writer)
        {
          this.writer.setCharacter(this.data.article.word);
        }
        if(this.writerAnim)
        {
          this.writerAnim.setCharacter(this.data.article.word);
        }

        if(!this.writer)
        {
          this.writer = HanziWriter.create(this.$refs.drawer, this.data.article.word,
          {
            width       : 76,
            height      : 76,
            padding     : 0,
            strokeColor :'#a1a1a1'
          });
        }
        if(!this.writerAnim)
        {
          this.writerAnim = HanziWriter.create(this.$refs.drawerAnim, this.data.article.word,
          {
            width               : 256,
            height              : 256,
            padding             : 0,
            showOutline         : true,
            showCharacter       : false,
            strokeAnimationSpeed: 0.5,
            delayBetweenStrokes : 10,
            outlineColor        :'#d2d5d5',
            strokeColor         :'#ffce00'
          });
        }
      }
    }
  },
  mounted:function()
  {
    this.updateChars();
    this.updateVoicActive();
  },
  watch: 
  { 
    data: function(newVal, oldVal)
    {
      this.updateChars();
      this.updateVoicActive();
    }
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 80%;
  max-width: 1000px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
  padding-bottom: 20px;

  .searchCont
  {
    max-width: 490px;
    min-width: 100px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
  }

  .cont
  {
    width: 800px;
    margin-left: auto;
    margin-right: auto;

    .wordCont
    {
      width: 100%;
      min-height: 110px;
      background-color:#f9f7f4; 
      display: flex;
      justify-content: space-between;
      box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.1);

      .left
      {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 20px;

        .sound-icon
        {
          background-image: url(./../assets/img/icons/sound.svg);
          background-repeat: no-repeat;
          background-size: contain;
          width: 14px;
          height: 10px;
          margin-top: 3px;
          margin-left: 5px;
          cursor: pointer;
          transition: all .5s;

          &:hover
          {
            transform: scale(1.5);
          }
        }

        .bigWordCont
        {
          display: flex;
          align-items: center;
          .word
          {
            font-size: 30px;
            margin-right: 5px;
          }
        }
        
        .transcriptions
        {
          .item
          {
            font-size: 12px;
            display: flex;

            .item-text
            {
              transition: all 0.1s;
              height: 14px;
              text-transform: lowercase;
              font-family: Arial, Helvetica, sans-serif;
            }
          }
        }

        .traditional
        {
          color: #ffce00;
          font-size: 23px;
        }
      }

      .right
      {
        width: 80px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .staticView
        {
          border: solid 2px #a1a1a1;
          width: 76px;
          height: 76px;
          position: relative;

          .staticChar
          {
            cursor: pointer;
            transition: all 0.5s;
          }

          .playIcon
          {
            background-image: url(./../assets/img/icons/play.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 18px;
            left: 18px;
            border-radius: 50%;
            position: absolute;
            pointer-events: none;
            transition: all 0.5s;
          }

          &:hover
          {
            .staticChar
            {
              opacity: 0.5;
            }
            
            .playIcon
            {
              transform: scale(1.2);
              box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
            } 
          }
        }

        .hiddenIcon
        {
          .playIcon
          {
            opacity: 0;
          }

          .staticChar
          {
            opacity: 1 !important;
          }
        }

        .hidden
        {
          display:none;
        }
        
        .animatedCharFrame
        {
          transition: all .5s;
          background-color: #ffffff;
          width: 270px;
          height:270px;
          top: 170px;
          left: -185px;
          z-index: 100;
          position: absolute;
          box-shadow: -3px 4px 8px 0px rgba(0,0,0,0.2);
          transform-origin: top right;

          .close
          {
            position: absolute;
            cursor: pointer;
            background-image: url(./../assets/img/icons/close-X.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 18px;
            height: 18px;
            top: -25px;
            right: 0px;
            transition: all .5s;

            &:hover
            {
              transform: scale(1.3);
            }
          }

          .animatedChar
          {
            background-color: #fafafa;
            margin-top: 7px;
            margin-left: 7px;
            width: 256px;
            height:256px;
            cursor: pointer;
          }
        }

        .hiddenFrame
        {
          transform: scale(0.32);
          top: 0;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    .transCont
    {
      position: relative;
      
      .translation
      {
        margin-top: 10px;
        padding:20px;
        width: calc(100% - 40px);
        min-height: 280px;
        font-size: 12px;
        background-color:#f9f7f4; 
        box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.1);
        font-feature-settings:initial;
        transition: all .1s;

        /deep/
        .dict-curly-brackets
        {
          color:#39a0ca;
          font-weight: bold;
          font-size: 20px;
        }

        /deep/
        .link_pic
        {
          cursor: pointer;
        }

        /deep/
        .link_pic_icon
        {
          background-image: url(./../assets/img/icons/picture.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          width: 15px;
          height: 13px;
          display: inline-block;
          margin-left: 5px;
          margin-right: 5px;
          pointer-events: none;
        }

        /deep/
        .link_pic_text
        {
          pointer-events: none;
        }

        /deep/
        .dict-roman-numbers
        {
          font-weight: bold;
          color: #2c7873;
        }
        
        /deep/
        .dict-numbers
        {
          font-weight: bold;
          color: #000000;
        }
        
        /deep/
        .dict-chinese-grammar
        {
          color:#478559;
        }

        /deep/
        .dict-angle-brackets
        {
          color:#7f00ff;
        }

        /deep/
        .dict-chinese-brackets
        {
          color:#3088ea;
        }
        
        /deep/
        .dict-example
        {
          font-weight: bold;
          color: #000000;
        }

        /deep/
        .bullet
        {
          margin-left: 30px;
        }

        /deep/
        .dict-see-also
        {
          color: #000000;
          text-decoration: underline;
        }

        /deep/
        .currentSearchedWord
        {
          // color: #3088ea;
          background-color: #fcd73d;
          display: inline;
        }
      }

      .picFrame
      {
          transition: all .5s;
          // background-color: #ffffff;
          transform-origin: top right;
          width: 270px;
          height:270px;
          z-index: 100;
          top: 50px;
          right: 15px;
          position: absolute;
          box-shadow: -3px 4px 8px 0px rgba(0,0,0,0.2);

          .close
          {
            position: absolute;
            cursor: pointer;
            background-image: url(./../assets/img/icons/close-X.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 18px;
            height: 18px;
            top: -25px;
            right: 0px;
            transition: all .5s;

            &:hover
            {
              transform: scale(1.3);
            }
          }

          .picture
          {
            // background-image: url(https://i.ytimg.com/vi/eScymVXBG9c/hqdefault.jpg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height:100%;
          }
      }
      .hiddenFrame
      {
        transform: scale(0.32);
        top: 0;
        opacity: 0;
        pointer-events: none;
      }

      .zoomBlock
      {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        top: 0px;
        user-select: none;
        right: -30px;
        width: 30px;
        height:180px;

        .labelCont
        {
          height: 120px;
          width: 100%;

          .label
          {
            width: 120px;
            transform: rotate(90deg);
            transform-origin: 0% 50%;
            margin-top: -10px;
            margin-left: 15px;
            text-align: center;
          }
        }

        .btn
        {
          background-color: #cccccc;
          width: 90%;
          height: 20px;
          
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all .5s;

          .label
          {
            font-weight: bold;
            font-feature-settings: normal;
          }

          &:hover
          {
            background-color: #333333;
            color: #ffffff;
          }
        }

        .disabledBtn
        {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }

    .feedback
    {
      cursor: pointer;
      margin-top: 10px;
      margin-bottom: 20px;
      display: flex;
      float: right;
      font-weight: bold;
      font-size: 10px;
      font-feature-settings: initial;
      height: 16px;

      .mail-icon
      {
        background-image: url(./../assets/img/icons/mail.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 14px;
        height: 14px;
        margin-top: 2px;
        margin-right: 5px;
        transition: all .5s;
      }

      
      &:hover
      {
        .mail-icon
        {
          transform: scale(1.5);
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .viewCont
  {
    width: 94% !important;
    margin-left: 2% !important;

    /deep/ .cont
    {
      width: 100% !important;
      margin-left: 0% !important;

      .zoomBlock
      {
        display: none !important;
      }
    }
  }
}
</style>
