<template>
  <div class="viewCont">
    <ArticleCont class="cont" v-bind:strokePadding="0" v-bind:strokeSize="64" v-bind:title="data.title.title" v-bind:small="data.title.small">
      <div class="content">
        <div class="gridHead">
          <div class="item" v-for="(item) in data.header" v-bind:key="'header_'+item.ge">
            <div class="title_ge">{{item.ge}}</div>
            <!-- <div class="title_ch">{{item.ch}}</div> -->
          </div>
        </div>
        <div class="gridBody">
          <div class="item" v-for="(item) in data.list" v-bind:key="'item_'+item.finin">
            <!-- <div class="cell num"     >{{item.num     }}</div> -->
            <div class="cell ch"      >{{item.ch      }}</div>
            <div class="cell finin"   >{{item.finin   }}</div>
            <div class="cell ge"      >{{item.ge      }}</div>
            <div class="cell count"   >{{item.count   }}</div>
            <div class="cell location">{{item.location}}</div>
          </div>
        </div>
      </div>
    </ArticleCont>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'Ethnic',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  mounted:function()
  {
     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 100%;
  height: auto;

  .cont
  {
    position: relative;
    margin-top: 80px;

    .content
    {
      width: 80%;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      font-feature-settings: initial;

      .gridHead
      {
        font-size: 14px;
        text-align: left;
        font-weight: bold;
        display: flex;

        .item
        {
            padding-left: 1%;
            width: 20%;
        }
      }
      
      .gridBody
      {
        margin-top:20px;
        margin-bottom: 50px;
        font-size: 14px;
        text-align: left;

        .item
        {
          display: flex;
          border-bottom: solid 1px #dedede;

          .cell
          {
                padding-left: 1%;
                width: 20%;
                display: inline-flex;
                flex-flow: column;
                justify-content: center;
          }
        }

        .ch,
        .ge,
        .location
        {
          background-color: #f6edce;
        }
        .location
        {
            padding-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .articleCont
  {
    margin-bottom: 10px !important;
    /deep/ .titleCont
    {
        overflow: hidden;
        font-size: 16px !important;
        width: 90% !important;
        margin-top: 0px !important;
        margin-right: 0% !important;
        margin-left: 0% !important;
        padding-left: 5% !important;
        padding-top: 0% !important;
    }

    .container
    {
      .content
      {
        margin-left: 5% !important;
        width: 90% !important;

        .gridHead
        {
          font-size: 10px !important;
          text-align: center;

          .item
          {
            overflow: hidden;
            line-break: anywhere;
            padding:0 !important;
          }
        }

        .gridBody
        {
          font-size: 10px !important;
            overflow: hidden;
            line-break: anywhere;
            padding:0 !important;
        }
      }
    }
  }
}
</style>
