<template>
  <div class="viewCont">
    <ArticleCont class="cont" v-bind:strokePadding="0" v-bind:strokeSize="64" v-bind:title="data.title.ge" v-bind:title2="data.title.ch">
      <div class="content">
        <div class="gridHead">
          <div class="item" v-for="(item) in data.header" v-bind:key="'header_'+item.ch">
            <div class="title_ge">{{item.ge}}</div>
            <div class="title_ch">{{item.ch}}</div>
          </div>
        </div>
        <div class="gridBody">
          <div class="item" v-for="(item) in data.list" v-bind:key="'item_'+item.enName">
            <div class="cell num"   >{{item.num   }}</div>
            <div class="cell symbol">{{item.symbol}}</div>
            <div class="cell chName">{{item.chName}}</div>
            <div class="cell geName">{{item.geName}}</div>
            <div class="cell enName">{{item.enName}}</div>
          </div>
        </div>
      </div>
    </ArticleCont>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'ChemicalElements',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  mounted:function()
  {
     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 100%;
  height: auto;

  .cont
  {
    position: relative;
    margin-top: 80px;

    .content
    {
      width: 80%;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      font-feature-settings: initial;

      .gridHead
      {
        font-size: 14px;
        text-align: left;
        font-weight: bold;
        display: flex;

        .item
        {
          
            width: 20%;
        }
      }
      
      .gridBody
      {
        margin-top:20px;
        margin-bottom: 50px;
        font-size: 14px;
        text-align: left;

        .item
        {
          display: flex;
          border-bottom: solid 1px #dedede;

          .cell
          {
            width: 20%;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1000px)
{
  .articleCont
  {
    margin-bottom: 10px !important;
    /deep/ .titleCont
    {
        overflow: hidden;
        font-size: 16px !important;
        width: 90% !important;
        margin-top: 0px !important;
        margin-right: 0% !important;
        margin-left: 0% !important;
        padding-left: 5% !important;
        padding-top: 0% !important;
    }

    .container
    {
      .content
      {
        margin-left: 5% !important;
        width: 90% !important;

        .gridHead
        {
          font-size: 10px !important;
          text-align: center;

          .item
          {
            overflow: hidden;
            line-break: anywhere;
            padding:0 !important;
          }
        }

        .gridBody
        {
          font-size: 10px !important;
            overflow: hidden;
            line-break: anywhere;
            padding:0 !important;
        }
      }
    }
  }
}
</style>
