import AppData      from './AppData';
import DomParser    from './DomParser';
import DomGenerator from './DomGenerator'; 
import StringParser from './StringParser';

class DataService
{
    constructor()
    {
        this._data = AppData;
    }

    get data()
    {
        return this._data;
    }

    loadViews(type,cb)
    {
        if(!this._data.views[type])
        {
            this._getData('./data/'+type+'.json','GET',(e)=>
            {
                let data = JSON.parse(e);
                this._data.views[type] = data
    
                if(cb)
                {
                    cb(this._data.views[type]);
                }
            });
        }
        else
        {
            if(cb)
            {
                cb(this._data.views[type]);
            }
        }
    }

    getLocalData(key)
    {
        if(self.localStorage)
        {
            let storageKey = 'cd-settings';
            let obj = self.localStorage.getItem(storageKey);

            if(obj)
            {
                obj = JSON.parse(obj);

                if(obj[key])
                {
                    return obj[key];
                }
            }
            return undefined;
        }
    }

    setLocalData(key,value)
    {
        if(self.localStorage)
        {
            let storageKey = 'cd-settings';
            let obj = self.localStorage.getItem(storageKey);

            if(obj)
            {
                obj = JSON.parse(obj);

                obj[key] = value;
            }
            else
            {
                obj = {};
                obj[key] = value;
            }

            self.localStorage.setItem(storageKey,JSON.stringify(obj));
        }
    }

    getArticle(article,cb)
    {
        this._getData(this._data.general.service+'Article/'+article,'GET',(e)=>
        {
            let data = JSON.parse(e);
            data.pictures = {};

            if(data.transcription)
            {
                let dom          = StringParser.parseTranslation(data.translation);
                
                data.translation = DomGenerator.updateLinks(dom,(picId)=>
                {
                    this.getPicture(picId,(pic)=>
                    {
                        data.pictures[picId] = pic;
                    })
                }).outerHTML;//DomGenerator.parseString(DomParser.parseDomToString(dom)).outerHTML;//dom;// DomParser.parseDom(dom);
            }
            this.data.selectedArticle = data;

            if(cb)
            {
                cb();
            }
        });
    }

    getPicture(id,cb)
    {
        this._getData(this._data.general.service+'picture/'+id,'GET',(e)=>
        {
            let obj = null;
            try
            {
                obj = JSON.parse(e);
            }
            catch(e){}

            if(obj)
            {
                let img = 'data:'+obj.mimeType+';base64,'+obj.picture;
                if(cb)
                {
                    cb(img);
                }
            }
        });
    }

    search(word,cb)
    {
        if(word.length>0)
        {
            this._getData(this._data.general.service+'Search/'+word,'GET',(e)=>
            {
                let data = JSON.parse(e);

                this.data.searchResults.length = 0;
                for(let num1 = 0; num1 < data.length; num1++)
                {
                    data[num1].meanings = data[num1].matchingText;
                    // data[num1].meanings = data[num1].meanings.split('<<').join(' <span class="dict-curly-brackets spacing">');
                    // data[num1].meanings = data[num1].meanings.split('>>').join('</span> ');
                    data[num1].meanings = data[num1].meanings.split('<<').join('');
                    data[num1].meanings = data[num1].meanings.split('>>').join('');
                    data[num1].meanings = data[num1].meanings.split(word).join('<b class=\'currentSearchedWord\'>'+word+'</b>');
                    

                    this.data.searchResults.push({id:data[num1].articleId,type:'search',meanings:[data[num1].meanings],key:data[num1].word});
                }
                if(this.data.searchResults.length==0)
                {
                    this.data.searchResults.push(null);
                }
                if(cb)
                {
                    cb();
                }
            });
        }
        else
        {
            this.data.searchResults.length = 0;
        }
    }

    searchArticle(word,cb)
    {
        if(word.length>0)
        {
            this._getData(this._data.general.service+'Partial/'+word,'GET',(e)=>
            {
                let data = JSON.parse(e);

                this.data.searchResults.length = 0;
                for(let num = 0; num < data.length; num++)
                {
                    this.data.searchResults.push({id:data[num].articleId,title:data[num].word,key:data[num].word});
                }
                if(this.data.searchResults.length==0)
                {
                    this.data.searchResults.push(null);
                }
                if(cb)
                {
                    cb();
                }
            });
        }
        else
        {
            this.data.searchResults.length = 0;
        }
    }

    searchWordInArticle(word,cb)
    {
        if(word.length>0)
        {
            if(!StringParser.includesGeorgian(word))
            {
                this._getData(this._data.general.service+'Decompose/'+word,'GET',(e)=>
                {
                    let data = JSON.parse(e);

                    this.data.searchResults.length = 0;
                    for(let num1 = 0; num1 < data.length; num1++)
                    {
                        for(let num2 = 0; num2 < data[num1].meanings.length; num2++)
                        {
                            data[num1].meanings[num2] = DomParser.prepareRow(StringParser.parseTranslation(data[num1].meanings[num2]));
                            data[num1].meanings[num2] = DomGenerator.updateLinks(data[num1].meanings[num2],(picId)=>{}).innerHTML;
                        }
                        data[num1].meanings = DomParser.updareRows(data[num1].meanings);

                        this.data.searchResults.push({id:data[num1].articleId,meanings:data[num1].meanings,key:data[num1].word});
                    }
                    if(this.data.searchResults.length==0)
                    {
                        this.data.searchResults.push(null);
                    }
                    if(cb)
                    {
                        cb();
                    }
                });
            }
            else
            {
                this.data.searchResults.length = 0;
                this.data.searchResults.push(null);
                if(cb)
                {
                    cb();
                }
            }
        }
        else
        {
            this.data.searchResults.length = 0;
        }
    }

    getArticleCount()
    {
        this._getData(this._data.general.service+'Count','GET',(e)=>
        {
            let data = e;
            this._data.views.main.dictionary.wordCount = Number.parseInt(data);
        });
    }

    getAnnouncement(onAnnouncement)
    {
        this._getData(this._data.general.service+'GetAnnouncementHash','GET',(token)=>
        {
            if(token != this._data.message.token)
            {
                this._getData(this._data.general.service+'GetAnnouncement','GET',(e)=>
                {
                    let data = JSON.parse(e);

                    let date = new Date(data.announcedAtUtc * 1000);

                    this._data.message.text .ge = data.announcementTextGe;
                    this._data.message.text .ch = data.announcementTextCn;
                    this._data.message.title.ge = data.announcementHeaderGe;
                    this._data.message.title.ch = data.announcementHeaderCn;
                    this._data.message.date     = ( date.getUTCDate    ().length == 1 ? '0' : '') +  date.getUTCDate ()    + '/' +
                                                  ((date.getUTCMonth()+1).length == 1 ? '0' : '') + (date.getUTCMonth()+1) + '/' +
                                                    date.getUTCFullYear();

                    this._data.message.token  = token;
                    this._data.message.opened = true;
                    
                    this.setLocalData('messageToken',this._data.message.token);

                    if(onAnnouncement)
                    {
                        onAnnouncement(this._data.message);
                    }
                });
            }
        });
    }

    sendMail(data,cb)
    {
        this._getData(this._data.general.service+'SendMail','POST',(e)=>
        {
            if(cb)
            {
                cb(e);
            }
        },
        JSON.stringify({
            senderAddress:data.email,
            subject      :data.name,
            text         :data.text
        }));
    }

    ////////////////////////////////////
    _getData(url,type,onComplete,params)
    {
        type   = type   == undefined ? "GET" : type;
        params = params == undefined ? {}    : params;

        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function()
        {
            if (this.readyState == 4)
            {
                if(this.status == 200)
                {
                    if(onComplete)
                    {
                        onComplete(this.responseText);
                    }
                }
                if(this.status == 400)
                {
                    if(onComplete)
                    {
                        onComplete('[]');
                    }
                }
            }
        };
        xhttp.open(type, url, true);
        xhttp.setRequestHeader('Content-Type', 'application/json');
        xhttp.send(params);
    }
}

export default new DataService();