<template>
  <div class="dedicationViewCont">
    <Background class="background" color="e6eeee" v-bind:index="bgIndex" v-bind:backgrounds="backgrounds" >
      <ArticleCont title="dedication" v-bind:bold="true" v-bind:strokePadding="55" v-bind:strokeSize="120">
        <div class="centerCont">
          <div class="photo"></div>
          <div v-bind:class="'text_'+language()"></div>
        </div>
      </ArticleCont>
    </Background>
  </div>
</template>

<script>
import Background from './../elements/Background.vue';
import ArticleCont from './../elements/ArticleCont.vue';
import BaseComponent from './../base/BaseComponent.vue';

export default {
  name: 'DedicationView',
  extends:BaseComponent,
  components: 
  {
    Background,
    ArticleCont
  },
  props:
  {
    backgrounds:Array,
    bgIndex:Number
  },
  created:function()
  {
  }
}
</script>
<style scoped lang="scss">
.dedicationViewCont
{
  display : block;
  position: relative;
  width   : 100%;
  height  : 320px;
  background-color: #fafafa;
  
  .background
  {
    width: 100%;
    height: 100%;

    .centerCont
    {
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;

      .photo
      {
        background-image: url('/img/content/dedication/photo.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 70% 50%;
        width: 40%;
        height: 80%;
      }

      .text
      {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 30% 50%;
        width: 60%;
        height: 100%;
      }

      .text_ge
      {
        @extend .text;
        background-image: url('/img/content/dedication/text_ge.png');
      }

      .text_ch
      {
        @extend .text;
        background-image: url('/img/content/dedication/text_ch.png');
      }
    }
  }
}
</style>
