<template>
  <div class="viewCont">
    <div class="search">
      <router-link v-bind:to="'/transcriptions/'+block.title" class="item" v-for="block in data" v-bind:key="'blockSearch_'+block.title">{{block.title}}</router-link>
    </div>
    <div class="viewCont-Content">
      <div class="grids">
        <div class="cont" v-for="block in data" v-bind:key="'block_'+block.title">
          <div class="title">{{block.title}}</div>
          <div class="content">
            <div class="gridHead">
              <div class="item num" ></div>
              <div class="item" v-for="(item) in block.header" v-bind:key="'header_'+item.ge">
                <div class="title_ge">{{item.ge}}</div>
                <div class="title_ch">{{item.ch}}</div>
              </div>
            </div>
            <div class="gridBody">
              <div class="item" v-for="(item) in block.list" v-bind:key="'item_'+block.title+'_'+item.finin">
                <div class="cell num"  >{{item.num  }}</div>
                <div class="cell finin">{{item.finin}}</div>
                <div class="cell ge"   >{{item.ge   }}</div>
                <div class="cell ru"   >{{item.ru   }}</div>
                <div class="cell en"   >{{item.en   }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'Transcriptions',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  methods:
  {
    scroll:function()
    {
      let scroll = this.$route.params.id ? this.data[this.$route.params.id].pos : 0;
      
      window.scrollTo({
        top: scroll,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  watch:
  {
    '$route'()
    {
      this.scroll();
    }
  },
  mounted:function()
  {
    this.scroll();
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  .search
  {
    position: fixed;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    width: fit-content;
    color: #ffffff;
    font-weight: bold;
    background-color: #ffce00;
    font-feature-settings: initial;
    right: calc(2vw + 22px);
    top: calc(50% - 240px);

    .item
    {
      margin-left: 2px;
      margin-right: 2px;
      cursor: pointer;
      transition: all .5s;
      text-decoration: none;
      color: #ffffff;

      &:hover
      {
        opacity: 0.5;
      }
    }  
  }

  .viewCont-Content
  {
    margin-top: 50px;

    .grids
    {
      display: flex;
      flex-flow: wrap;
      justify-content: flex-start;

      .cont
      {
        margin-top: 50px;
        position: relative;
        margin-left: 2%;
        margin-right: 2%;
        width: 46%;
        height:auto;

        .title
        {
          font-size: 30px;
          font-feature-settings: initial;
          font-weight: bold;
          color: #ffce00;
          border-bottom: solid 2px #dedede;
        }

        .content
        {
          margin-top: 10px;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          font-feature-settings: initial;

          .gridHead
          {
            font-size: 14px;
            text-align: left;
            font-weight: bold;
            display: flex;

            .item
            {
                width: 22.5%;
            }

            .num
            {
              width: 10%;
            }
          }
          
          .gridBody
          {
            margin-top:20px;
            margin-bottom: 50px;
            font-size: 14px;
            text-align: left;

            .item
            {
              display: flex;
              border-bottom: solid 1px #dedede;

              .cell
              {
                display: inline-flex;
                flex-flow: column;
                justify-content: center;
                width: 22.5%;
              }

              .num
              {
                width: 10%;
              }

              .ru,
              .en
              {
                color: #666666;
              }
            }
          }
        }
      }
    }
  }


}

@media only screen and (max-width: 1000px)
{
  .viewCont
  {
    width: 90% !important;
    /deep/ .grids
    {
      .cont
      {
        overflow: hidden;
        width: 100% !important;
        margin-top: 0px !important;
        margin-right: 0% !important;

        .gridHead
        {
          .title_ge
          {
            font-size: 10px;
          }

          .title_ch
          {
            font-size: 10px;
          }
        }
      }
    }

    /deep/ .search
    {
      display: none;
      right: 50% !important;
      top: unset !important;
      bottom: 1%;
      flex-direction: row;
      transform: translateX(50%);
      padding-top: 5px;
      padding-bottom: 5px;
      z-index: 1;
      font-size: 12px;
    }
  }

}
</style>
