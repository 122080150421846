import Vue from 'vue'
import App from './App.vue'
import router from './router'
import dataService from './services/DataService';
import LanguageManager from './services/LanguageManager';
import VueResource from 'vue-resource';
import Actions from './services/Actions';

let languages = new LanguageManager(onLanguagesLoaded);

function onLanguagesLoaded()
{  
  let zoom      = {variants:[12,14,16,18],index:0};
  let actions   = new Actions(router,languages,dataService,zoom);

  Vue.config.productionTip = false;
  
  Vue.use(VueResource);
  
  new Vue({
    data:
    {
      actions  :actions,
      appData  :dataService.data,
      languages:languages,
      zoom     :zoom
    },
    router,
    render: h => h(App)
  }).$mount('#app');
}