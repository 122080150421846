<template>
  <div class="ourTeamViewCont">
    <div class="background">
      <ArticleCont title="ourTeam" v-bind:bold="true" v-bind:strokePadding="75" v-bind:strokeSize="120">
        <div class="centerCont">
          <div class="mainAuthor">
            <div class="photo" v-bind:style="{backgroundImage:'url('+data.photo+')'}"></div>
            <div class="info">
              <div class="name">{{getWord('texts.ourTeam.director')}}</div>
              <div class="position">{{getWord('texts.ourTeam.directorTitle')}}</div>
            </div>
          </div>
          <div class="content">
            <div class="info">              
              <div class="position" v-html="getWord('texts.ourTeam.deputyTitle')"></div>
              <div class="names"    v-html="getWord('texts.ourTeam.deputy')"></div>
            </div>
            <div class="info">              
              <div class="position" v-html="getWord('texts.ourTeam.editorialDepartmentTitle')"></div>
              <div class="names"    v-html="getWord('texts.ourTeam.editorialDepartment')"></div>
            </div>
            <div class="info">              
              <div class="position" v-html="getWord('texts.ourTeam.editorsTitle')"></div>
              <div class="names"    v-html="getWord('texts.ourTeam.editors')"></div>
            </div>
            <div class="info">              
              <div class="position" v-html="getWord('texts.ourTeam.participantsTitle')"></div>
              <div class="names"    v-html="getWord('texts.ourTeam.participants')"></div>
            </div>
            <div class="info">              
              <div class="position" v-html="getWord('texts.ourTeam.georgianEditorsTitle')"></div>
              <div class="names"    v-html="getWord('texts.ourTeam.georgianEditors')"></div>
            </div>
            <div class="info">              
              <div class="position" v-html="getWord('texts.ourTeam.chineseEditorsTitle')"></div>
              <div class="names"    v-html="getWord('texts.ourTeam.chineseEditors')"></div>
            </div>
            <div class="info">              
              <div class="position" v-html="getWord('texts.ourTeam.onlineAuthorsTitle')"></div>
              <div class="names"    v-html="getWord('texts.ourTeam.onlineAuthors')"></div>
            </div>
            <div class="info">              
              <div class="position" v-html="getWord('texts.ourTeam.additionalDataTitle')"></div>
              <div class="names"    v-html="getWord('texts.ourTeam.additionalData')"></div>
            </div>
            <div class="info">              
              <div class="position" v-html="getWord('texts.ourTeam.webDesignersTitle')"></div>
              <div class="names"    v-html="getWord('texts.ourTeam.webDesigners')"></div>
            </div>
          </div>
        </div>
      </ArticleCont>
    </div>
  </div>
</template>

<script>
import ArticleCont from './../elements/ArticleCont.vue';
import BaseComponent from './../base/BaseComponent.vue';

export default {
  name: 'OurTeamView',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  }
}
</script>
<style scoped lang="scss">
.ourTeamViewCont
{
  display: block;
  position: relative;
  width: 100%;
  height: 500px;
  background-color: #fafafa;
  
  .background
  {
    width: 100%;
    height: 100%;

    .centerCont
    {
      background-image: url('./../../assets/img/backgrounds/stripe.png');
      width: 100%;
      top: 5%;
      position: relative;
      height: 90%;
      display: flex;
      align-content: center;
      justify-content: space-evenly;
      align-items: center;

      .mainAuthor
      {
          width: 20%;
          height: 80%;
          .photo
          {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            width: 100%;
            height: 80%;
          }
          .info
          {
            text-align: center;
            width: 100%;
            margin-top: 20px;

            .name
            {
              font-weight: bold;
              font-size: 0.8rem;
              color: #ffce00;
            }

            .position
            {
              margin-top: 5px;
              font-size: 0.7rem;
              font-feature-settings:normal;
              font-style: italic;
            }
          }
      }

      .content
      {
        width: 60%;
        height: 100%;
        margin-top: 20px;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;

        .info
        {
          width: 30%;
          margin-bottom: 15px;
          margin-right: 10px;
          .position
          {
              font-weight: bold;
              font-size: 0.9rem;
              color: #ffce00;
          }
          .names
          {
              font-size: 0.8rem;
              margin-top: 5px;
              font-feature-settings:normal;
              font-style: italic;
          }
        }
      }
    }
  }
}
</style>
