import Vue              from 'vue';
import DataService      from './../services/DataService';
import VueRouter        from 'vue-router';
import MainPage         from '../views/MainPage.vue';
import Article          from '../views/Article.vue';
import Abbreviations    from '../views/Abbreviations.vue';
import ChemicalElements from '../views/ChemicalElements.vue';
import DynastyPeriods   from '../views/DynastyPeriods.vue';
import Measures         from '../views/Measures.vue';
import Transcriptions   from '../views/Transcriptions.vue';
import Ethnic           from '../views/Ethnic.vue';
import Contact          from '../views/Contact.vue';
import Seasons          from '../views/Seasons.vue';
import Holidays         from '../views/Holidays.vue';
import About            from '../views/About.vue';
import Translations     from '../views/Translations.vue';

Vue.use(VueRouter);

let props = 
{
};

const routes = 
[
  {path: '/',                   name:'/',               component: MainPage,        props:props},
  {path: '/dedication',         name:'dedication',      component: MainPage,        props:props},
  {path: '/dictionary',         name:'dictionary',      component: MainPage,        props:props},
  {path: '/ourTeams',           name:'ourTeams',        component: MainPage,        props:props},
  {path: '/teamValues',         name:'teamValues',      component: MainPage,        props:props},
  {path: '/chemical',           name:'chemical',        component: ChemicalElements,props:props},
  {path: '/seasons',            name:'seasons',         component: Seasons         ,props:props},
  {path: '/measures',           name:'measures',        component: Measures        ,props:props},
  {path: '/chronology',         name:'chronology',      component: DynastyPeriods  ,props:props},
  {path: '/abbreviations',      name:'abbreviations',   component: Abbreviations,   props:props},
  {path: '/transcriptions',     name:'transcriptions',  component: Transcriptions,  props:props},
  {path: '/transcriptions/:id', name:'transcriptionsID',component: Transcriptions,  props:props},
  {path: '/ethnic',             name:'ethnic',          component: Ethnic,          props:props},
  {path: '/holidays',           name:'holidays',        component: Holidays,        props:props},
  {path: '/about',              name:'about',           component: About,           props:props},
  {path: '/article/:id',        name:'article',         component: Article,         props:props},
  {path: '/article/:id/:hl',    name:'articleHighlight',component: Article,         props:props},
  {path: '/library/',           name:'library',         component: Translations,    props:props},
  {path: '/contact',            name:'contact',         component: Contact,         props:{data:DataService.data}}
];

const router = new VueRouter({routes});

router.beforeEach((to, from, next) => 
{
  switch (to.name)
  {
    case 'article':
    {
      DataService.data.loading              = true;
      DataService.data.pageKey              = to.fullPath;
      DataService.data.searchResults.length = 0;
      DataService.data.selectedArticle      = null;
      DataService.getArticle(to.params.id,()=>
      {
        props.data = {article:DataService.data.selectedArticle,search:DataService.data.searchResults};
        DataService.data.loading = false;
        next();
      });
      break;
    }
    case 'articleHighlight':
    {
      DataService.data.loading              = true;
      DataService.data.pageKey              = to.fullPath;
      DataService.data.searchResults.length = 0;
      DataService.data.selectedArticle      = null;
      DataService.getArticle(to.params.id,()=>
      {
        props.data = {article:DataService.data.selectedArticle,search:DataService.data.searchResults};
        if(to.params.hl && to.params.hl.length>0)
        {
          props.data.article.translation = props.data.article.translation.split(to.params.hl).join('<b class=\'currentSearchedWord\'>'+to.params.hl+'</b>');
          
          let el = document.createElement('div');
          el.innerHTML = props.data.article.translation;

          let arr = el.getElementsByTagName('a');

          console.log(arr);
          for(let num = 0; num < arr.length; num++)
          {
            if(arr[num].hasAttribute('href'))
            {
              let att = arr[num].getAttribute('href').split('<b class=\'currentSearchedWord\'>').join('').split('</b>').join('');
              arr[num].setAttribute('href',att);
            }
            if(arr[num].hasAttribute('picture'))
            {
              let att = arr[num].getAttribute('picture').split('<b class=\'currentSearchedWord\'>').join('').split('</b>').join('');
              arr[num].setAttribute('picture',att);
            }
            
          }

          props.data.article.translation = el.innerHTML;
        }
        DataService.data.loading = false;
        next();
      });
      break;
    }
    case '/'         :
    case 'dedication':
    case 'dictionary':
    case 'ourTeams'  :
    case 'teamValues':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('main',(data)=>
      {
        props.data = {data:data,search:DataService.data.searchResults};
        DataService.getArticleCount();
        next();
      })
      break;
    }
    case 'chemical':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('chemicalElements',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    case 'seasons':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('seasons',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    case 'measures':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('measures',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    case 'chronology':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('chronology',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    case 'abbreviations':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('abbreviations',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    case 'transcriptions'  :
    case 'transcriptionsID':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('transcriptions',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    case 'ethnic':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('ethnic',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    case 'holidays':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('holidays',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    case 'about':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('about',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    case 'library':
    {
      DataService.data.pageKey = 'none';
      DataService.loadViews('translations',(data)=>
      {
        props.data = data;
        next();
      })
      break;
    }
    default:
    {
      DataService.data.pageKey = 'none';
      next();
      break;
    }
  }
})
export default router;