<template>
  <div class="cont" v-bind:style="{backgroundImage:'url('+background+')',backgroundColor:(color ? '#'+color : '#ffffff')}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Background',
  props:
  {
    backgrounds:Array,
    color:String,
    index:Number
  },
  data: function ()
  {
    return {
      background:'',
    }
  },
  mounted:function()
  {
    let num = this.index != undefined ? this.index : Math.floor(Math.random() * Math.floor(this.backgrounds.length));
    
    this.background = this.backgrounds[num];
  }
}
</script>
<style scoped lang="scss">
.cont
{
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: multiply;
}
</style>
