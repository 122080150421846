let DomGenerator = 
{
    updateBrackets(str,tagName,newTag,className,removePrefixSuffix,newPrefixSuffixInside,newPrefix,newSuffix,overrideParams)
    {
        overrideParams        = overrideParams        === undefined ? ''     : overrideParams;
        newTag                = newTag                === undefined ? 'span' : newTag;
        removePrefixSuffix    = removePrefixSuffix    === undefined ? true   : removePrefixSuffix;
        newPrefixSuffixInside = newPrefixSuffixInside === undefined ? true   : newPrefixSuffixInside;

        if (!String.prototype.splice) 
        {
            String.prototype.splice = function(start, delCount, newSubStr) 
            {
                return this.slice(0, start) + newSubStr + this.slice(start + Math.abs(delCount));
            };
        }

        let newPrefOut = (newPrefix && !newPrefixSuffixInside ? newPrefix : '');
        let newSuffOut = (newSuffix && !newPrefixSuffixInside ? newSuffix : '');

        let newPrefIn  = (newPrefix &&  newPrefixSuffixInside ? newPrefix : '');
        let newSuffIn  = (newSuffix &&  newPrefixSuffixInside ? newSuffix : '');

        let prefix = '['+tagName;
        let suffix = '[/'+tagName+']';

        let prefStartIndex = str.indexOf(prefix);
        let prefEndIndex   = -1;
        let params         = '';

        if(prefStartIndex>-1)
        {
            let temp = str.substr(prefStartIndex);

            prefEndIndex = temp.indexOf(']');

            if(prefEndIndex>-1)
            {
                prefEndIndex = prefStartIndex + prefEndIndex;
            }
            if(prefStartIndex>-1 && prefEndIndex>-1 && prefStartIndex < prefEndIndex)
            {
                prefix = str.substr(prefStartIndex,(prefEndIndex+1)-prefStartIndex);

                params = prefix.replace('['+tagName,'').replace(']','');
            }
        }
        
        let start  = str.indexOf(prefix);
        let end    = str.indexOf(suffix);

        if(start>-1 && end>-1)
        {
            if(params.length == 0 && overrideParams.length>0)
            {
                params = overrideParams+(str.substring(start+prefix.length,end).trim())+'"';
            }

            if(removePrefixSuffix == true)
            {
                str = str.replace(prefix,'');
            }
            str = str.splice(start,0,newPrefOut+'<'+newTag+' '+params+' class='+className+'>'+newPrefIn);

            end = str.indexOf(suffix);

            if(removePrefixSuffix == true)
            {
                str = str.replace(suffix,'');
            }
            str = str.splice(end+(removePrefixSuffix == false ? suffix.length : 0),0,newSuffIn+'</'+newTag+'>'+newSuffOut);
        }
        return str;
    },

    parseTextToElements(str)
    {
        str = this.updateBrackets(str,'ch-grammar'   ,'span','dict-chinese-grammar' ,true,true );
        str = this.updateBrackets(str,'example'      ,'span','dict-example'         ,true,true );
        str = this.updateBrackets(str,'roman-number' ,'span','dict-roman-numbers'   ,true,true );
        str = this.updateBrackets(str,'bullet'       ,'span','bullet'               ,true,true );
        str = this.updateBrackets(str,'see-link'     ,'a'   ,'dict-see-also'        ,true,false,' იხ. '         , ' ','href="/#/article/');
        str = this.updateBrackets(str,'see-ant-link' ,'a'   ,'dict-see-also'        ,true,false,' იხ ანტ. '     , ' ','href="/#/article/');
        str = this.updateBrackets(str,'same-as-link' ,'a'   ,'dict-see-also'        ,true,false,' იგივეა, რაც '  , ' ','href="/#/article/');
        str = this.updateBrackets(str,'see-also-link','a'   ,'dict-see-also'        ,true,false,' ასევე '        , ' ','href="/#/article/');
        str = this.updateBrackets(str,'number'       ,'span','dict-numbers'         ,true,true ,  '', '. ');
        str = this.updateBrackets(str,'ch-brackets'  ,'span','dict-chinese-brackets',true,true ,'【','】');
        str = this.updateBrackets(str,'curly'        ,'span','dict-chinese-grammar' ,true,true );
        // str = this.updateBrackets(str,'curly'        ,'span','dict-chinese-grammar' ,true,true ,'{' ,'}' );
        str = this.updateBrackets(str,'angle'        ,'span','dict-angle-brackets'  ,true,true ,'〈','〉');
        
        return str;
    },

    parseString(str)
    {
        let cont = document.createElement('div');
        let arr  = str.split('\n');

        for(let num = 0; num < arr.length; num++)
        {
            let div = document.createElement('div');
            cont.appendChild(div);
            
            let str = arr[num];
            for(let num2 = 0; num2 < 10; num2++)
            {
                str = this.parseTextToElements(str);
            }
            div.innerHTML = (str.length == 0) ? '<br>' : str;
        }

        return cont;
    },

    updateLinks(str,onPicture)
    {
        let cont = document.createElement('div');
        cont.innerHTML = str;

        let as = cont.getElementsByTagName('a');

        for(let num = 0; num < as.length; num++)
        {
            let href    = as[num].getAttribute('href');
            let articleOrigin  = '/get-article?word=';
            let articleReplace = '/#/article/';
            let pictureOrigin  = '/get-picture?pictureName=';
            let pictureReplace = '';

            if(href.indexOf(articleOrigin)>-1)
            {
                href = href.replace(articleOrigin,articleReplace);
                as[num].setAttribute('href',href);
            }
            if(href.indexOf(pictureOrigin)>-1)
            {
                href = href.replace(pictureOrigin,pictureReplace);
                as[num].removeAttribute('onclick');
                as[num].removeAttribute('href');
                as[num].setAttribute('picture',href);
                as[num].setAttribute('class','link_pic');

                if(onPicture)
                {
                    onPicture(href);
                }
                
                let txt = as[num].innerText;
                as[num].innerHTML = '<span class="link_pic_text">'+txt+'</span><span class="link_pic_icon"></span>';
            }
        }
        return cont;
    }
};

try 
{
    module.exports = DomGenerator;
}
catch (e){}