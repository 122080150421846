<template>
  <div class="viewCont">
    <ArticleCont class="cont" v-bind:strokePadding="0" v-bind:strokeSize="64" v-bind:title="data.title.ge" v-bind:title2="data.title.ch">
      <div class="content">
        <div class="periodCont" v-for="(period,periodIndex) in data.list" v-bind:key="'periodCont_'+periodIndex">
          <div class="period">
            <div class="title title_ge">{{period.period.ge}}</div>
            <div class="title title_ch">{{period.period.ch}}</div>
            <div v-if="period.period.date" class="date">{{period.period.date}}</div>
          </div>
          <div class="epochCont">
            <div class="epoch" v-for="(epoch,epochIndex) in period.epochs" v-bind:key="'epochCont_'+epochIndex">
              <div class="dynastyCont">
                <div v-if="epoch.name" v-bind:class="['dynastyName',(epoch.dynasties ? 'small' : '')]">
                  <div class="title title_ge">{{epoch.name.ge}}</div>
                  <div class="title title_ch">{{epoch.name.ch}}</div>
                  <div v-if="epoch.name.date" class="date">{{epoch.name.date}}</div>
                </div>
                <div v-if="epoch.date" class="date">{{epoch.date}}</div>
                <div v-if="epoch.dynasties" class="dynasties">
                  <div class="dynasty" v-for="(dynasty,dynastyIndex) in epoch.dynasties" v-bind:key="'dynasty_'+dynastyIndex">
                    <div class="titles">
                      <div class="title title_ge">{{dynasty.ge}}</div>
                      <div class="title title_ch">{{dynasty.ch}}</div>
                    </div>
                    <div v-if="dynasty.date" class="date">{{dynasty.date}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="period.date" class="date">{{period.date}}</div>
        </div>
      </div>
    </ArticleCont>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'DynastyPeriods',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  mounted:function()
  {
     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
</script>
<style scoped lang="scss">
@mixin center
{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@mixin border($top:true,$bottom:true,$left:true,$right:true)
{
  @if $top
  {
    border-top : solid 1px #dedede;
  }
  @if $bottom
  {
    border-bottom : solid 1px #dedede;
  }
  @if $left
  {
    border-left : solid 1px #dedede;
  }
  @if $right
  {
    border-right : solid 1px #dedede;
  }
}

.viewCont
{
  display: block;
  width: 100%;
  height: auto;

  .cont
  {
    position: relative;
    margin-top: 80px;

    .content
    {
      width: 80%;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      font-feature-settings: initial;

      .periodCont
      {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 14px;
        margin-bottom: 20px;

        .period
        {
          @include center;
          @include border(true,true,true,false);
          width: 30%;
          flex-shrink: 0;

          .date
          {
            background-color: unset;
          }
        }
        
        .epochCont
        {
          @include border(true,true,false,false);
          width: 70%;

          .epoch
          {
            width: 100%;
            @include border(false,false,true,false);

            .dynastyName
            {
              @include center;
              @include border(false,true,false,false);
              width: 100%;
            }

            .dynastyCont
            {
              display: flex;
              width: 100%;

              .dynastyName
              {
                @include border(false,false,false,true);
                .date
                {
                  background-color: unset;
                }
              }

              .date
              {
                @include border(false,true,false,false);
                &:last-child
                {
                  border-bottom: none;
                }
              }

              .small
              {
                width: 50%;
              }

              .dynasties
              {
                width: 100%;
                .dynasty
                {
                  display: flex;
                  width: 100%;
                  @include border(false,true,false,false);

                  .titles
                  {
                    width: 100%;
                  }

                  &:last-child
                  {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }

        .title
        {
          text-align: center;
        }

        .date
        {
          @include center;
          width: 155px;
          flex-shrink: 0;
          background-color: #f6edce;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .articleCont
  {
    margin-bottom: 10px !important;
    /deep/ .titleCont
    {
        overflow: hidden;
        font-size: 16px !important;
        width: 90% !important;
        margin-top: 0px !important;
        margin-right: 0% !important;
        margin-left: 0% !important;
        padding-left: 5% !important;
        padding-top: 0% !important;
    }

    .container
    {
      .content
      {
        margin-left: 5% !important;
        width: 90% !important;
        font-size: 10px !important;
        overflow: hidden;
        line-break: anywhere;
        padding:0 !important;
        font-size: 10px !important;

        .date
        {
          width: 50% !important;
          font-size: 10px !important;
          padding-top: 2px;
          padding-bottom: 2px;
        }

        .title
        {
          font-size: 10px !important;
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
    }
  }
}
</style>
