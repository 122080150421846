<template>
  <div class="viewCont">
    <div class="viewCont-Content">
      <ArticleCont class="cont" v-bind:title="block.title.ge" v-bind:title2="block.title.ch" v-bind:strokePadding="0" v-bind:strokeSize="64" v-bind:noPadding="true" v-for="(block,blockIndex) in data.blocks" v-bind:key="'block_'+blockIndex">
        <div class="content">
          <div class="gridHead">
            <div class="item" v-for="(item) in block.header" v-bind:key="'header_'+item.ge">
              <div class="title_ge">{{item.ge}}</div>
              <div class="title_ch">{{item.ch}}</div>
            </div>
          </div>
          <div class="gridBody">
            <div class="item" v-for="(item) in block.list" v-bind:key="'item_'+item.nameGe">
              <div class="cell">
                <div class="cellTitle ge"   >{{item.nameGe  }}</div>
                <div class="cellTitle ch"   >{{item.nameCh  }}</div>
              </div>
              <div class="cell abr"    >{{item.abr     }}</div>
              <div class="cell coef"   >{{item.coef    }}</div>
            </div>
          </div>
        </div>
      </ArticleCont>
    </div>
  </div>
</template>
<script>
import BaseComponent  from '@/components/base/BaseComponent.vue';
import ArticleCont    from '@/components/elements/ArticleCont.vue';

export default {
  name: 'Measures',
  extends:BaseComponent,
  components: 
  {
    ArticleCont
  },
  props:
  {
    data:Object
  },
  mounted:function()
  {
     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}
</script>
<style scoped lang="scss">
.viewCont
{
  display: block;
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  .viewCont-Content
  {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    margin-top: 50px;

    .cont
    {
      position: relative;
      margin-left: 2%;
      margin-right: 2%;
      width: 46%;
      height:auto;

      .content
      {
        margin-top: 10px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-feature-settings: initial;

        .gridHead
        {
          font-size: 14px;
          text-align: left;
          font-weight: bold;
          display: flex;

          .item
          {
              padding-left: 1%;
              width: 33.3%;
          }
        }
        
        .gridBody
        {
          margin-top:20px;
          margin-bottom: 50px;
          font-size: 14px;
          text-align: left;

          .item
          {
            display: flex;
            border-bottom: solid 1px #dedede;

            .cell
            {
                  padding-left: 1%;
                  width: 33.3%;
                  display: inline-flex;
                  flex-flow: column;
                  justify-content: center;

                  .cellTitle
                  {
                    width: 100%;
                  }
            }
          }

          .ch,
          .ge,
          .location
          {
            background-color: #f6edce;
          }
          .location
          {
              padding-bottom: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .viewCont
  {
    width: 90% !important;

    .viewCont-Content
    {
      display: block !important;
      width: 100% !important;

      .articleCont
      {
        overflow: hidden;
        margin: 0% !important;
        width: 100% !important;
        margin-bottom: 10px !important;
        
        /deep/ .titleCont
        {
            overflow: hidden;
            font-size: 16px !important;
            width: 100% !important;
            margin-top: 0px !important;
            margin-right: 0% !important;
            margin-left: 0% !important;
            padding-left: 0% !important;
            padding-top: 0% !important;
        }

        .container
        {
          .content
          {
            margin-top: 10px !important;
            margin-left: 0% !important;
            width: 100% !important;

            div
            {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
}
</style>
