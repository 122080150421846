<template>
  <div class="articleCont">
    <div v-bind:class="['titleCont',(bold == true ? 'boldTitle':''),(noPadding == true ? 'noPadding' : '')]">
      <div class="title">{{getWord(title)}}</div>
      <div class="title" v-if="title2">{{title2}}</div>
      <div class="title small" v-if="small">{{small}}</div>
      <div class="stroke" v-bind:style="{marginLeft:(strokePadding!=undefined ? strokePadding + 'px' : ''),width:(strokeSize!=undefined ? strokeSize + 'px' : '')}"></div>
    </div>
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BaseComponent from '../base/BaseComponent.vue'
export default {
  name: 'ArticleCont',
  extends:BaseComponent,
  props:
  {
    title        :String,
    title2       :String,
    small        :String,
    bold         :Boolean,
    noPadding    :Boolean,
    strokePadding:Number,
    strokeSize   :Number
  }
}
</script>
<style scoped lang="scss">
.articleCont
{
  position: absolute;
  width: 100%;
  height: 100%;

  .titleCont
  {
    padding-top: 30px;
    padding-left: 75px;
    font-size: 20px;
    width: 30%;

    .small
    {
      font-weight: normal !important;
      font-size: 14px;
    }

    .stroke
    {
      width: 50%;
      margin-left: 10%;
      height: 5px;
      background-color: #ffce00;
    }
  }

  .noPadding
  {
    padding-left: 0px !important;
  }

  .boldTitle
  {
    font-weight: bold;
  }

  .container
  {
      width: 100%;
      height: calc(100% - 80px);
      position: relative;
  }
}
</style>
