export default class Actions
{
    constructor(router,lang,dataService,zoom)
    {
        this._router  = router;
        this._lang    = lang;
        this._zoom    = zoom;
        this._service = dataService;

        let selectedLang = this._service.getLocalData('lang');
        let selectedZoom = this._service.getLocalData('zoom');
        this._service.data.message.token = this._service.getLocalData('messageToken');

        this._lang.language = selectedLang != undefined ? selectedLang : 'ge';
        this._zoom.index    = selectedZoom != undefined ? selectedZoom : 0;

        this._service.getAnnouncement(()=>
        {
            if(this._service.data.message.text[this._lang.language].length == 0)
            {
                this._service.data.message.opened = false;
                // this._service.setLocalData('messageToken','');
            }
        });

    }

    onAction(type,data)
    {
        console.log(type,data);
        
        switch(type)
        {
            case 'onZoom'          :{this.onZoom             (data); break;}
            case 'onLanguageButton':{this.onLanguage         (data); break;}
            case 'onMenuButton'    :{this.onMenuButton       (data); break;}
            case 'onSearch'        :{this.searchArticle      (data); break;}
            case 'onSearchItem'    :{this.onArticleSelect    (data); break;}
            case 'onWordSearch'    :{this.searchWordInArticle(data); break;}
            case 'onArticleSearch' :{this.search             (data); break;}
            case 'sendMail'        :{this.sendMail           (data); break;}
            case 'closeMessage'    :{this.closeMessage       (data); break;}
        }
    }

    closeMessage()
    {
        this._service.data.message.opened = false;
    }

    onZoom(data)
    {
      this._zoom.index += data;
      this._zoom.index = this._zoom.index < 0 ? 0 : this._zoom.index;
      this._zoom.index = this._zoom.index > this._zoom.variants.length-1 ? this._zoom.variants.length-1 : this._zoom.index;

      this._service.setLocalData('zoom',this._zoom.index);
    }

    onLanguage(data)
    {
        this._lang.language = data.key;

        this._service.setLocalData('lang',data.key);

        if(this._service.data.message.text[this._lang.language].length == 0)
        {
            this._service.data.message.opened = false;
        }
    }

    onMenuButton(data)
    {
        if(data.path && data.path!=this._router.currentRoute.name)
        {
            this._router.push(data.path);
        }
    }

    searchArticle(data)
    {
        this._service.searchArticle(data);
    }

    searchWordInArticle(data)
    {
        this._service.searchWordInArticle(data);
    }

    search(data)
    {
        this._service.search(data);
    }

    onArticleSelect(data)
    {
        if('/article/'+data.data.key!=this._router.currentRoute.fullPath)
        {
            this._router.push('/article/'+data.data.key+(data.key && data.key.length>0 ? '/' + data.key : ''));
        }
    }

    sendMail(data)
    {
        this._service.sendMail(data,(e)=>
        {
            this.tempAlert("message sent", 3000, this._router);
            
        });
    }

    tempAlert(msg,duration, router)
    {
        var w = window.alert(msg)
        setTimeout(function() {w.close();router.push('/');}, duration)
      /*  var el = document.createElement("div");
        el.setAttribute("style","position:absolute;top:40%;left:20%;background-color:white;");
        el.innerHTML = msg;
        setTimeout(function(){
            el.parentNode.removeChild(el);
            router.push('/');
        },duration);
        document.body.appendChild(el);*/
    }

    get service()
    {
        return this._service;
    }
}