<template>
  <div class="scrollable-container" ref="scrollableCont">
    <slot></slot>
  </div>
</template>

<script>
export default 
{
  name: 'ScrollableContainer',
  props:
  {
   scroll:Number
  },
  watch:
  {
    scroll: function (newVal, oldVal)
    {
      this.$refs.scrollableCont.scrollTo({
        top: newVal,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
<style scoped lang="scss">

.scrollable-container::-webkit-scrollbar
{
  width: 12px;
}

.scrollable-container
{
  scrollbar-width: thin;
  scrollbar-color: rgba(#ffce00, 1) transparent ;
}
.scrollable-container::-webkit-scrollbar-track
{
  background: transparent;
}
.scrollable-container::-webkit-scrollbar-thumb
{
  background-color: rgba(#ffce00, 1);
  border-radius: 10px;
  cursor: pointer;
}

.scrollable-container 
{
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>