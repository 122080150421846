<template>
  <div class="cont">
    <div class="menuButton"   v-on:click="onMenu(true)"></div>
    <div v-bind:class="['logo_Text',(selectedLanguage == 'ch' ? 'logo_Text_ch' : ''),(selected != '/' ? 'logo_NoText' : '')]" v-on:click="onButton({path:'/'})"></div>
    <transition name="fade">
      <div class="overlay" v-if="menuActive == true">
        <div class="blend" v-on:click="onMenu(false)"></div>
        <div class="menu" v-on:mouseleave="onMenu(false)">
          <div class="menuLogo" v-on:click="onButton({path:'/'})"></div>
          <div class="languages">
            <div v-on:click="onLanguage(item)" v-for="(item,index) in menu.languages" v-bind:key="'lang_'+index" v-bind:class="['button',(isSelectedLanguage(item) ? 'selected' : '')]">
              <div class="title" v-if="item.title">{{getWord(item.title)}}</div>
              <div class="bottom"></div>
            </div>
          </div>
          <div class="buttons">
            <div v-on:click="onButton(item)" v-for="(item,index) in menu.buttons" v-bind:key="'lang_'+index" v-bind:class="['button',(isSelected(item) && !item.sub ? 'selected' : '')]">
              <div class="title" v-if="item.title">{{getWord(item.title)}}</div>
              <div v-if="item.sub" v-bind:class="['sub-cont',(item.opened != true ? 'closed' : '')]">
                <div class="subs">
                  <div v-on:click="onButton(sub)" v-bind:class="['button-sub',(sub.path == '/'+selected ? 'selected' : '')]" v-for="(sub,subIndex) in item.sub" v-bind:key="'item_sub_'+subIndex">{{getWord(sub.title)}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseComponent from './base/BaseComponent.vue';

export default {
  name: 'Menu',
  extends:BaseComponent,
  props:
  {
    menu:Object,
    selected:String,
    selectedLanguage:String
  },
  data:function()
  {
    return {
      menuActive:false
    }
  },
  methods:
  {
    onMenu:function(val)
    {
      this.menuActive = val;
    },
    onLanguage:function(val)
    {
        this.menuActive = false;
        this.onAction('onLanguageButton',val);
    },
    onButton:function(val)
    {
      if(val.sub)
      {
        for(let num = 0; num < this.menu.buttons.length; num++)
        {
          this.menu.buttons[num].opened = false;
        }
        val.opened = true;
      }
      
      if(val.path)
      {
        this.menuActive = false;
        this.onAction('onMenuButton',val);
      }
    },
    isSelectedLanguage:function(item)
    {
      return item.key == this.selectedLanguage;
    },
    isSelected:function(item)
    {
      if(item.path == '/'+this.selected)
      {
        return true;
      }
      return false;
    }
  }
}
</script>
<style scoped lang="scss">
.cont
{
  width: 100%;
  height:100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  .menuButton
  {
    background-color: #ffce00;
    -webkit-mask-image:url(./../assets/img/icons/menu.svg);
    mask-image: url(./../assets/img/icons/menu.svg);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
    -webkit-mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: contain;
    width: 60px;
    height: 28px;
    top: calc(2vh + 28px);
    left: 2vw;
    position: fixed;
    cursor: pointer;
    pointer-events: all;
    transition: all 0.5s;

    &:hover
    {
      background-color: #dcb326;
    }
  }

  .logo
  {
    background-image: url(./../assets/img/icons/logo_red.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 2vh;
    right: 2vw;
    cursor: pointer;
    pointer-events: all;
  }

  .logo_Text
  {
    background-image: url(./../assets/img/icons/logo_text.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    width: 200px;
    height: 60px;
    position: absolute;
    top: 2vh;
    right: 2vw;
    cursor: pointer;
    pointer-events: all;
    transition: all .2s;
  }

  .logo_Text_ch
  {
    background-image: url(./../assets/img/icons/logo_text_ch.svg);
    width: 105px;
  }

  .logo_NoText
  {
    width: 55px;
    background-size: cover;
    background-position: left center;
  }

  .overlay
  {
    pointer-events: all;
    position: fixed;
    width: 100vw;
    height: 100vh;
    transition: all 1s;

    .blend
    {
      width: 100vw;
      height: 100vh;
      position: fixed;
    }
    .menu
    {
      transition: all 1s;
      position: fixed;
      width: 225px;
      height: 100vh;
      background-color: #5b5a59;
      box-shadow: 1px 0px 2px 0px rgba(0,0,0,0.75);

      
      .menuLogo
      {
        background-image: url(./../assets/img/icons/logo_white.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 60px;
        height: 60px;
        margin-top: 2vh;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        pointer-events: all;
      }

      .languages
      {
        display: flex;
        justify-content: center;
        color: #ffffff;
        margin-top: 20%;

        .button
        {
          cursor: pointer;
          margin-left: 2%;
          margin-right:2%;

          .title
          {
            height:1.2rem;
          }

          .bottom
          {
            margin-top: 5px;
            border-bottom:solid 2px transparent;
            transition: all .5s;
          }

          &:hover
          {
            .bottom
            {
              border-bottom:solid 2px #ffce00;
            }
          }
        }

        .selected
        {
            .bottom
            {
              border-bottom:solid 2px #ffce00;
            }
        }
      }

      .buttons
      {
        margin-top: 20%;

        .button
        {
          text-align: center;
          margin-bottom: 20px;
          cursor: pointer;
          transition: all .5s;
          
          .title
          {
            color: #ffffff;
            font-size:1rem;
            transition: all .5s;

            &:hover
            {
                color:#ffce00;
            }
          }

          .sub-cont
          {
            cursor: auto;
            overflow: hidden;
            transition: all .5s;

            .subs
            {
              transition: all .5s;
              .button-sub
              {
                font-size:0.9rem;
                margin-top: 10px;
                color:#9e9c9b;
                cursor: pointer;
                transition: all .5s;

                &:hover
                {
                    color:#ffce00;
                }
              }

              .selected
              {
                color:#ffce00;
              }
            }
          }

          .closed
          {
            height: auto;
            .subs
            {
              margin-top: -100%;
              opacity: 0;
            }
          }
        }

        .selected
        {
          .title
          {
            color:#ffce00;
          }
        }
      }


      .item
      {
        color: #ffffff;
        font-size: 0.8rem;
        text-align: center;
        
        .item-buttons-cont
        {
          display: flex;
          justify-content: center;

          .button
          {
            cursor: pointer;
            margin-left: 2%;
            margin-right:2%;

            .button-title
            {
              height:1rem;
            }
            .button-bottom
            {
              border-bottom:solid 2px transparent;
              transition: all .5s;
            }

            &:hover
            {
              .button-bottom
              {
                border-bottom:solid 2px #ffce00;
              }
            }
          }
          .selected
          {
            .button-bottom
            {
              border-bottom:solid 2px #ffce00;
            }
          }
        }

        .item-sub-cont
        {
          color: #9e9c9b;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px)
{
  .menuButton
  {
    left: 0px !important;
    top: calc(1vh + 10px) !important;
  }
  .menu
  {
    width: 100% !important;
  }

  .logo_Text
  {
    background-size: contain !important;
    background-position-x: right !important;
    top: calc(1vh + 10px) !important;
    height: 30px !important;
  }

  .logo_NoText
  {
    background-size: cover !important;
    background-position-x: left !important;
    width: 28px !important;
  }
}

.fade-enter-active, .fade-leave-active
{
  .menu
  {
    left: 0px;
  }
}
.fade-enter, .fade-leave-to
{
  .menu
  {
    left: -300px;
  }
  opacity: 0;
}
</style>
