<template>
</template>
<script>
export default 
{
  name: 'BaseComponent',
  methods:
  {
    zoomIndex:function()
    {
      return this.$root.zoom.index;
    },
    zoomValue:function()
    {
      return this.$root.zoom.variants[this.$root.zoom.index] + 'px';
    },
    isMaxZoom:function()
    {
      return this.$root.zoom.index >= this.$root.zoom.variants.length-1;
    },
    isMinZoom:function()
    {
      return this.$root.zoom.index <= 0;
    },
    language:function()
    {
      return this.$root.languages.language;
    },
    getWord:function(val)
    {
      return this.$root.languages.getWord(val);
    },
    onAction:function(ev,data)
    {
      this.$emit('action',ev,data);
    }
  }
}
</script>