<template>
  <div class="MissionViewCont">
    <div class="values">
      <div v-for="(item,index) in data.values" v-bind:key="'mission_item_'+index" class="value">
        <div class="icon"  v-bind:style="{backgroundImage:'url('+item.icon+')'}"></div>
        <div class="title" v-html="getWord(item.title)"></div>
        <div class="text"  v-html="getWord(item.text )"></div>
      </div>
    </div>
    <div class="textCont">
      <div class="content">
        <div>
          <div v-html="getWord('texts.mission.info')"></div>
          <div class="stroke"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseComponent from './../base/BaseComponent.vue';

export default {
  name: 'MissionView',
  extends:BaseComponent,
  props:
  {
    data:Object
  }
}
</script>
<style scoped lang="scss">
.MissionViewCont
{
  display: block;
  position: relative;
  width: 100%;
  height: 620px;
  background-color: #fafafa;

  .values
  {
    position: relative;
    top: 50px;
    height: 400px;
    display: flex;
    justify-content: space-evenly;
    .value
    {
      width: 26%;
      margin-left:2%;
      margin-right: 2%;

      .icon
      {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 70px;
        height: 70px;
        margin-left: auto;
        margin-right: auto;
      }

      .title
      {
        margin-top: 20px;
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
      }

      .text
      {
        margin-top: 20px;
        font-size: 0.8rem;
        text-align: justify;
        font-feature-settings: normal;
        font-style: italic;

      }
    }
  }

  .textCont
  {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    min-width:500px;
    height: 120px;
    border: solid 4px #a0a0a0;

    .content
    {
      margin-top: 15px;
      margin-left: -10%;
      background-color: #fafafa;
      width: 120%;
      height: 80px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      
      text-align: center;
      font-feature-settings: normal;
      font-style: italic;
      font-size: 0.76rem;

      .stroke
      {
        width: 30%;
        margin-top: 10px;
        margin-left: 80%;
        height: 5px;
        background-color: #ffce00;
      }
    }
  }
}
</style>
